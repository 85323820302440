import { Col, Collapse, Row } from "antd";
import { useTranslation } from "react-i18next";
import { millisecondsToHoursMinutesSecondsTextLabel } from "src/utils";

interface Props {
  isHowMinutesWorkPanelOpen: number;
  setIsHowMinutesWorkPanelOpen: (isOpen: number) => void;
  maxVoiceCallDuration: number;
  maxTimeQuota: number;
  resetMinutes: number;
}

const Rules: React.FC<Props> = ({
  isHowMinutesWorkPanelOpen,
  setIsHowMinutesWorkPanelOpen,
  maxVoiceCallDuration,
  maxTimeQuota,
  resetMinutes,
}) => {
  const { t } = useTranslation("voiceCalls");

  const maxDurationMinutes = Math.floor(maxVoiceCallDuration / (60 * 1000));
  const maxTimeQuotaMinutes = Math.floor(maxTimeQuota / (60 * 1000));
  const maxDurationFormatted =
    millisecondsToHoursMinutesSecondsTextLabel(maxVoiceCallDuration);
  const weeklyQuotaRuleHeader =
    maxTimeQuotaMinutes % 60 === 0
      ? t("startVoiceCallModal.You get {{hours}} hours of calls every week", {
          hours: maxTimeQuotaMinutes / 60,
        })
      : t(
          "startVoiceCallModal.You get {{minutes}} minutes of calls every week",
          { minutes: maxTimeQuotaMinutes }
        );
  const weeklyQuotaRuleDetail =
    maxTimeQuotaMinutes % 60 === 0
      ? t(
          "startVoiceCallModal.You are entitled to {{hours}} hours ({{minutes}} minutes) of free voice calls every week and can choose who you’d like to call__ Please note that if you have less than {{maxDurationFormatted}} remaining in your week that your call will be shorter than usual__",
          {
            hours: maxTimeQuotaMinutes / 60,
            minutes: maxTimeQuotaMinutes,
            maxDurationFormatted,
          }
        )
      : t(
          "startVoiceCallModal.You are entitled to {{minutes}} minutes of free voice calls every week and can choose who you’d like to call__ Please note that if you have less than {{maxDurationFormatted}} remaining in your week that your call will be shorter than usual__",
          { minutes: maxTimeQuotaMinutes, maxDurationFormatted }
        );
  return (
    <Collapse
      style={{ border: "0", backgroundColor: "#FFFFFF" }}
      activeKey={isHowMinutesWorkPanelOpen ? 1 : 0}
      onChange={() =>
        setIsHowMinutesWorkPanelOpen(1 - isHowMinutesWorkPanelOpen)
      }
    >
      <Collapse.Panel
        className="pb-3"
        style={{ border: "0" }}
        key={1}
        header={
          <span className="text-blue-600">
            {t(
              "startVoiceCallModal.How do voice call minutes work and when do they reset?"
            )}
          </span>
        }
      >
        <div className="overflow-x-hidden">
          {[
            {
              header: t(
                "startVoiceCallModal.A single call can be up to {{minutes}} minutes long",
                {
                  minutes: maxDurationMinutes,
                }
              ),
              detail: (
                <div className="text-s text-black opacity-65">
                  {t(
                    "startVoiceCallModal.If your call is less than {{minutes}} minutes, you may make additional calls with the remaining minutes you have available__ You will receive additional minutes for calls {{resetMinutes}} minutes after your most recent call__",
                    {
                      minutes: maxDurationMinutes,
                      resetMinutes,
                    }
                  )}
                </div>
              ),
            },
            {
              header: weeklyQuotaRuleHeader,
              detail: (
                <div className="text-s text-black opacity-65 pb-4">
                  <div className="pb-3">{weeklyQuotaRuleDetail}</div>
                  <div className="italic">
                    {t(
                      "startVoiceCallModal.**Your weekly limit resets every Monday morning__**",
                      {}
                    )}
                  </div>{" "}
                </div>
              ),
            },
          ].map(({ header, detail }, index) => (
            <div key={`firstTimeUserModal/${index}`}>
              <Row gutter={12}>
                <Col span={2} className="grid justify-items-end">
                  <div className="h-6 w-6 flex justify-center items-center rounded-full border border-gray-400 text-gray-400">
                    <p className="mb-px mr-px">{index + 1}</p>
                  </div>
                </Col>
                <Col span={22}>
                  <div className=" font-medium text-black-600/[0.45]">
                    {header}
                  </div>
                </Col>
              </Row>
              <Col offset={2} span={22}>
                <div className="pb-3">{detail}</div>
              </Col>
            </div>
          ))}
        </div>
        <div className="pb-2"></div>
      </Collapse.Panel>
    </Collapse>
  );
};

export default Rules;
