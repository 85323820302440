import {
  HomeOutlined,
  LogoutOutlined,
  MessageOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Badge, Divider, Layout, Menu, Row, Space, Typography } from "antd";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { FacilityFeature } from "src/api/graphql";
import { ReactComponent as Logo } from "src/assets/logo.svg";
import { GetUnreadMessageCountDocument } from "src/graphql/queries/GetUnreadMessageCount.generated";
import { useAppNavigate } from "src/hooks/useAppNavigate";
import useFacilityFeature from "src/hooks/useFacilityFeature";
import { Inmate } from "src/types/User";
import { getFullName } from "src/utils/utils";
import IndividualAvatar from "../Avatar/IndividualAvatar";
import Loader from "../Loader";

const { Sider } = Layout;
interface Props {
  user: Inmate | null;
  logout: () => void;
}

export default function Sidebar({ user, logout }: Props): ReactElement {
  const { t } = useTranslation("common");
  const navigate = useAppNavigate();
  const { data: unreadMessageCount } = useQuery(GetUnreadMessageCountDocument);
  const hasMessaging = useFacilityFeature(FacilityFeature.Emessaging);
  const hasVoiceCalls = useFacilityFeature(FacilityFeature.VoiceCall);

  return (
    <Sider theme="light" className="shadow-lg">
      <Space direction="vertical" className="w-full" size="large">
        <Row className="mt-4 ml-4 cursor-pointer" onClick={() => navigate("/")}>
          <Logo className="login-logo" />
        </Row>
        {user ? (
          <div>
            <Row
              align="middle"
              className="pl-4 cursor-pointer"
              onClick={() => navigate(`/profile/${user.id}`)}
            >
              <Space>
                <IndividualAvatar
                  src={user.profileImageUrl}
                  size={64}
                  fallback={getFullName(user)}
                  photosAllowed
                />
                <Typography.Text>{getFullName(user)}</Typography.Text>
              </Space>
            </Row>
            <Menu
              mode="inline"
              defaultSelectedKeys={["home"]}
              className="w-full"
            >
              <Menu.Item
                key="home"
                icon={<HomeOutlined />}
                onClick={() => navigate("/")}
              >
                {t("home")}
              </Menu.Item>
              <Menu.Item
                key="meetings"
                icon={<ScheduleOutlined />}
                onClick={() => navigate("/meetings")}
              >
                {t("meetings")}
              </Menu.Item>
              {(hasMessaging || hasVoiceCalls) && (
                <Menu.Item
                  key="inbox"
                  icon={hasMessaging ? <MessageOutlined /> : <TeamOutlined />}
                  onClick={() => navigate("/inbox")}
                >
                  <>
                    {hasMessaging ? t("messages") : t("connections")}{" "}
                    <Badge
                      count={
                        unreadMessageCount?.currentInmate?.unreadMessageCount
                      }
                      overflowCount={99}
                      offset={["0", "-0.2em"]}
                    />
                  </>
                </Menu.Item>
              )}
              <Menu.Item
                key="settings"
                icon={<SettingOutlined />}
                onClick={() => navigate("/settings")}
              >
                {t("settings")}
              </Menu.Item>
            </Menu>
          </div>
        ) : (
          <Loader />
        )}
        <Divider className="my-0" />
        <Menu mode="inline" className="w-full">
          <Menu.Item
            key="logout"
            icon={<LogoutOutlined />}
            onClick={logout}
            className="text-blue-500 opacity-100"
          >
            {t("logout")}
          </Menu.Item>
        </Menu>
      </Space>
    </Sider>
  );
}
