import { formatDate } from "src/utils";

interface Props {
  connectionFirstName: string;
  createdAt: number;
}

const ConnectionCreationCard = ({ connectionFirstName, createdAt }: Props) => {
  return (
    <div className="pt-3 text-xs text-black opacity-45 flex flex-col justify-center items-center">
      <div>{`You and ${connectionFirstName} became connections.`}</div>
      <div>{formatDate(new Date(createdAt), "monthdayyear")}</div>
    </div>
  );
};

export default ConnectionCreationCard;
