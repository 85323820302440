import { Avatar } from "antd";
import { AvatarSize } from "antd/lib/avatar/SizeContext";
import { VisitorFragmentFragment } from "src/graphql/fragments/VisitorFragment.generated";
import { getFullName, getInitials } from "src/utils";
import { getAvatarBackgroundColor } from "src/utils/UI";

interface Props {
  visitor: VisitorFragmentFragment;
  size?: AvatarSize;
}

const VisitorAvatar = ({ visitor, size }: Props) => {
  const bgColor = getAvatarBackgroundColor(getFullName(visitor));
  return (
    <Avatar key={visitor.id} size={size} className={`${bgColor} text-white`}>
      {getInitials(getFullName(visitor))}
    </Avatar>
  );
};

export default VisitorAvatar;
