import { Language } from "src/types/Session";
import { User } from "src/types/User";
import { client as apolloClient } from "../../api/apollo";

// TODO: use session slice
// https://github.com/Ameelio/pathways-client/issues/45
interface AuthInfo {
  id: string;
  type: "inmate";
  token: string;
}

type SessionStatus = "inactive" | "active" | "loading";

interface SessionState {
  isLoggedIn: boolean;
  authInfo: AuthInfo;
  user: User;
  language: Language;
  status: SessionStatus;
}

// Constants & Shapes
const SET_SESSION = "user/SET_SESSION";
const LOGOUT = "user/LOGOUT";
const SET_SESSION_STATUS = "user/SET_STATUS";
const SET_PROFILE = "user/SET_PROFILE";

interface SetSessionAction {
  type: typeof SET_SESSION;
  payload: SessionState;
}
interface LogoutAction {
  type: typeof LOGOUT;
}

interface SetSessionStatusAction {
  type: typeof SET_SESSION_STATUS;
  payload: SessionStatus;
}

interface SetProfileAction {
  type: typeof SET_PROFILE;
  payload: { profileImagePath?: string; hasFilledIntakeForm?: boolean };
}

type UserActionTypes =
  | LogoutAction
  | SetSessionAction
  | SetSessionStatusAction
  | SetProfileAction;

export const logout = (): UserActionTypes => {
  return {
    type: LOGOUT,
  };
};

export const setSession = (userState: SessionState): UserActionTypes => {
  return {
    type: SET_SESSION,
    payload: userState,
  };
};

export const setSessionStatus = (status: SessionStatus): UserActionTypes => {
  return {
    type: SET_SESSION_STATUS,
    payload: status,
  };
};

export const setProfile = ({
  profileImagePath,
  hasFilledIntakeForm,
}: {
  profileImagePath?: string;
  hasFilledIntakeForm?: boolean;
}): UserActionTypes => {
  return {
    type: SET_PROFILE,
    payload: {
      profileImagePath,
      hasFilledIntakeForm,
    },
  };
};

// Reducer

const NULL_USER = {
  id: "",
  firstName: "",
  lastName: "",
  location: "",
  dateOfBirth: "",
  inmateIdentification: "",
  callQuota: 0,
  visitQuota: 0,
  race: "",
  needsMonitor: true,
  needsApproval: true,
  hasFilledIntakeForm: false,
};

const initialState: SessionState = {
  authInfo: { id: "", type: "inmate", token: "" },
  user: NULL_USER,
  isLoggedIn: false,
  language: "en",
  status: "inactive",
};

export function sessionReducer(
  state = initialState,
  action: UserActionTypes
): SessionState {
  switch (action.type) {
    case SET_SESSION:
      return action.payload;
    case LOGOUT:
      apolloClient.clearStore();
      return {
        authInfo: { id: "", type: "inmate", token: "" },
        user: NULL_USER,
        isLoggedIn: false,
        status: "inactive",
        language: "en",
      };
    case SET_SESSION_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case SET_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          profileImagePath:
            action.payload.profileImagePath || state.user.profileImagePath,
          hasFilledIntakeForm:
            action.payload.hasFilledIntakeForm ||
            state.user.hasFilledIntakeForm,
        },
      };
    default:
      return state;
  }
}
