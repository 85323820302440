import {
  CheckOutlined,
  ClockCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Typography } from "antd";
import assertNever from "assert-never";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { client } from "src/api/apollo";
import { MessageAvailability } from "src/api/graphql";
import { MessageFragmentFragment } from "src/graphql/fragments/MessageFragment.generated";
import { UpdateMessageIsReadDocument } from "src/graphql/mutations/UpdateMessageIsRead.generated";
import { formatDate, getMessageSenderType } from "src/utils";
import MessageDetailModal from "./MessageDetailModal";

export type TMessageItem = MessageFragmentFragment & {
  sender: Pick<MessageFragmentFragment["sender"], "__typename" | "firstName">;
};
interface Props {
  message: TMessageItem;
  recipientFirstName: string;
  className?: string;
}

const MessageCard: React.FC<Props> = ({
  message,
  recipientFirstName,
  className,
}: Props) => {
  const { t } = useTranslation(["common", "messaging"]);
  const senderType = getMessageSenderType(message);
  const MessageErrorIcon = () =>
    message.availability === MessageAvailability.Rejected ? (
      <StopOutlined className="text-red-500" />
    ) : null;
  const MessageDeliveryIcon = () => {
    switch (message.availability) {
      case MessageAvailability.Available:
        return senderType === "inmate" ? (
          <CheckOutlined className="text-blue-500" />
        ) : null;
      case MessageAvailability.Pending:
        return <ClockCircleOutlined className="text-gray-500" />;
      case MessageAvailability.Rejected:
      case MessageAvailability.ProcessingFailed:
        return null;
      default:
        assertNever(message.availability, true);
    }
  };
  const MessageContent = () => {
    switch (message.availability) {
      case MessageAvailability.Available:
        return <span>{message.content}</span>;
      case MessageAvailability.Pending:
        return senderType === "inmate" ? (
          <span>{message.content}</span>
        ) : (
          <span className="text-gray-400">
            {t("messaging:messages.messageContent.pending")}
          </span>
        );
      case MessageAvailability.Rejected:
        return senderType === "inmate" ? (
          <span>{message.content}</span>
        ) : (
          <span className="text-gray-400">
            {t("messaging:messages.messageContent.rejected")}
          </span>
        );
      case MessageAvailability.ProcessingFailed:
        return null;
      default:
        assertNever(message.availability, true);
    }
  };
  const messageAvailabilityText = (() => {
    switch (message.availability) {
      case MessageAvailability.Available:
        return senderType === "inmate"
          ? t("messaging:messages.messageAvailability.delivered")
          : "";
      case MessageAvailability.Pending:
        return t("messaging:messages.messageAvailability.pending");
      case MessageAvailability.Rejected:
        return `Rejected${
          getMessageSenderType(message) === "inmate"
            ? `. ${t("messaging:messages.messageContent.clickForDetails")}`
            : ""
        }`;
      case MessageAvailability.ProcessingFailed:
        return null;
      default:
        assertNever(message.availability, true);
    }
  })();

  const [messageDetailModalIsOpen, setMessageDetailModalIsOpen] =
    useState(false);
  const onMessageClick = () => {
    if (message.availability === MessageAvailability.Rejected) {
      setMessageDetailModalIsOpen(true);
    }
  };

  const bgColor =
    senderType === "inmate"
      ? message.availability === MessageAvailability.Available
        ? "bg-blue-200"
        : "opacity-45 bg-blue-200"
      : "bg-white";

  const borderProps =
    senderType === "inmate"
      ? "rounded-t-lg rounded-l-lg"
      : "rounded-t-lg rounded-r-lg border border-gray-200"; //Visitor messages have 1px border

  const [updateMessageIsRead, { error: updateIsReadError }] = useMutation(
    UpdateMessageIsReadDocument
  );

  useEffect(() => {
    if (
      senderType === "visitor" &&
      !message.isRead &&
      message.availability === MessageAvailability.Available
    ) {
      updateMessageIsRead({
        variables: { input: { messageId: message.id } },
      });
      return () => {
        client.cache.modify({
          id: client.cache.identify({ __typename: "Message", id: message.id }),
          fields: {
            isRead: () => true,
          },
        });
      };
    }
  }, [message, senderType, updateMessageIsRead]);

  useEffect(() => {
    if (!updateIsReadError) return;
    Sentry.captureException(updateIsReadError);
  }, [updateIsReadError]);

  return (
    <>
      <div
        className={`grid gap-1 ${
          senderType === "visitor" ? "" : "justify-items-end	"
        } ${className || ""} ${
          message.availability === MessageAvailability.Rejected
            ? "cursor-pointer"
            : ""
        }`}
        onClick={onMessageClick}
      >
        <div
          className={`flex gap-2 items-center ${
            senderType === "visitor" ? "" : "flex-row-reverse"
          }`}
        >
          <MessageErrorIcon />
          <div className={`grid gap-2 p-2 ${bgColor} ${borderProps}`}>
            <div className="flex justify-between">
              <Typography.Text type="secondary" className="text-xs pr-8">
                {
                  message.sender.__typename === "Visitor"
                    ? message.sender.firstName
                    : t(
                        "common:you"
                      ) /*reading senderType doesn't do the required type narrowing.*/
                }
              </Typography.Text>
              <Typography.Text type="secondary" className="text-xs">
                {formatDate(new Date(message.createdAt), "monthdaytime")}
              </Typography.Text>
            </div>
            <MessageContent />
          </div>
          {!message.isRead &&
            senderType === "visitor" &&
            message.availability === MessageAvailability.Available && (
              <div className="flex flex-horizontal items-center">
                {" "}
                <div className="bg-primary-6 w-1.5 h-1.5 rounded-full" />{" "}
                <span className=" pl-1 text-primary-6 text-sm">
                  {t("common:new")}
                </span>
              </div>
            )}
        </div>
        <Typography.Text type="secondary" className="text-xs flex items-center">
          <MessageDeliveryIcon />
          <span className="pl-1">{messageAvailabilityText}</span>
        </Typography.Text>
      </div>
      <MessageDetailModal
        onClose={() => setMessageDetailModalIsOpen(false)}
        isOpen={messageDetailModalIsOpen}
        recipientFirstName={recipientFirstName}
        statusDetails={message.statusDetails || ""}
      />
    </>
  );
};

export default MessageCard;
