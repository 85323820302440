import { CloseOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useIntakeForm } from "src/components/ChicagoSurvey/useIntakeForm";
import Card from ".";
import SurveyButton from "../Common/Buttons/SurveyButton";

type Props = {
  title: string;
  body: string;
  onClose?: () => void;
  illustration: React.ReactNode;
  formId: string;
};

const SurveyCard = ({ title, body, onClose, illustration, formId }: Props) => {
  const [hide, setHide] = useState(false);
  const { onCompleteForm } = useIntakeForm();

  const handleClose = () => {
    setHide(true);
    onClose && onClose();
  };
  return (
    <Card className={`${hide ? "hidden" : ""}`}>
      <div className="flex gap-4">
        <div className="flex flex-col gap-4">
          <span className="font-bold text-lg">{title}</span>
          <span>{body}</span>
          <div>
            <SurveyButton
              formId={formId}
              type="primary"
              onSubmit={async () => {
                await onCompleteForm();
                handleClose();
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <CloseOutlined onClick={handleClose} className="self-end text-lg" />
          <div>{illustration}</div>
        </div>
      </div>
    </Card>
  );
};

export default SurveyCard;
