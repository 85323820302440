import { message, notification } from "antd";
import { MeetingType } from "src/api/graphql";

export function getRandomItem<TArrayItem>(items: TArrayItem[]): TArrayItem {
  return items[Math.floor(Math.random() * items.length)];
}

export const getInitials = (str: string) => {
  return str
    .split(" ")
    .map((substr) => substr[0])
    .join("");
};

export const getFullName = (entity: {
  firstName: string;
  lastName: string;
}): string => (entity ? `${entity.firstName} ${entity.lastName}` : "");

export const getVisitLabel = (
  visitType: MeetingType,
  ignoreContact?: boolean
) => {
  switch (visitType) {
    case MeetingType.VideoCall:
      return "Video Call";
    case MeetingType.InPersonVisit:
      return ignoreContact ? "In-Person Visit" : "In Person, With Contact";
    case MeetingType.VoiceCall:
      return "Voice Call";
    default:
      return visitType;
  }
};

export function toTitleCase(text: string): string {
  return text
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}

export const groupBy = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K,
  mustHave?: K[] // makes it so that if there are no items of key X, it will still be initialized as X: [] instead of X: undefined
) => {
  const result = list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);
  if (mustHave) {
    mustHave.forEach((key) => {
      if (!result[key]) {
        result[key] = [];
      }
    });
  }
  return result;
};

export const openNotificationWithIcon = (
  message: string,
  description: string,
  type: "success" | "info" | "error" | "warning"
) => {
  notification[type]({
    message,
    description,
  });
};
export const showToast = (
  key: string,
  content: string,
  type: "success" | "error" | "warning" | "loading" | "info",
  duration = 3
) => {
  switch (type) {
    case "success":
      message.success({ content, key, duration });
      break;
    case "error":
      message.error({ content, key, duration });
      break;
    case "warning":
      message.warning({ content, key, duration });
      break;
    case "loading":
      message.loading({ content, key, duration });
      break;
    default:
      message.info({ content, key, duration });
      break;
  }
};

export function notEmpty<TParam>(
  value: TParam | null | undefined
): value is TParam {
  return value !== null && value !== undefined;
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}
