import { useMutation, useQuery } from "@apollo/client";
import { Alert, Layout } from "antd";
import { default as React, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { client } from "src/api/apollo";
import {
  ConnectionStatus,
  ConnectionTimelineEventVerb,
  FacilityService,
} from "src/api/graphql";
import EventTimelineList from "src/components/Inbox/EventTimelineList";
import FirstMessageSentModal from "src/components/Inbox/FirstMessageSentModal";
import FirstTimeUserModal from "src/components/Inbox/FirstTimeUserModal";
import MessageCompose from "src/components/Inbox/MessageCompose";
import NoConnectionsView from "src/components/Inbox/NoConnectionsView";
import NoMessagesView from "src/components/Inbox/NoMessagesView";
import { CreateMessageDocument } from "src/graphql/mutations/CreateMessage.generated";
import { CreateVoiceCallDocument } from "src/graphql/mutations/CreateVoiceCall.generated";
import { GetConnectionsDocument } from "src/graphql/queries/GetConnections.generated";
import { GetConnectionTimelineDocument } from "src/graphql/queries/GetConnectionTimeline.generated";
import { useAppDispatch } from "src/redux";
import { closeModal, openModal } from "src/redux/modules/modalsSlice";
import { formatDate, reportApolloError } from "src/utils";
import { useServerEvent } from "src/utils/ServerEventsProvider";
import ConnectionListSidebar from "../components/Inbox/ConnectionListSidebar";
import { TConnectionListItem } from "../components/Inbox/ConnectionListSidebar/ConnectionListItem";
import InboxHeader from "../components/Inbox/InboxHeader";

interface Props {}
const InboxPage: React.FC<Props> = () => {
  const [currentInmateId, setCurrentInmateId] = useState<string>();
  const [hasSentAnyMessages, setHasSentAnyMessages] = useState<boolean>(true);
  type ModalStates = "open" | "neverOpened" | "closed";
  const [newUserIntroModalState, setNewUserIntroModalState] =
    useState<ModalStates>("neverOpened");
  const [firstMessageSentModalState, setFirstMessageSentModalState] =
    useState<ModalStates>("neverOpened");
  const [suggestedMessage, setSuggestedMessage] = useState("");
  const {
    data: connectionData,
    loading: connectionsLoading,
    refetch: refetchConnections,
  } = useQuery(GetConnectionsDocument, {
    nextFetchPolicy: "cache-only",
    onCompleted: (data) => {
      setCurrentInmateId(data?.currentInmate?.id);
      if (data.currentInmate && !data.currentInmate.hasSentAnyMessages) {
        setHasSentAnyMessages(false);
        if (newUserIntroModalState === "neverOpened") {
          setNewUserIntroModalState("open");
        }
      }
    },
  });
  const [selected, setSelected] = useState<TConnectionListItem | undefined>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["messaging"]);

  const {
    data: timelineData,
    loading: isTimelineLoading,
    refetch: refetchConnectionTimeline,
  } = useQuery(GetConnectionTimelineDocument, {
    variables: { id: selected?.id || "" },
    skip: !selected?.id,
    nextFetchPolicy: "cache-only", // Huh? does polling ignore this policy? it must.
    pollInterval: 30000,
    onError: (err) =>
      reportApolloError(err, "InboxPage/GetConnectionTimelineDocument"),
  });

  useServerEvent("updateConnectionTimeline", () => {
    refetchConnectionTimeline();
    dispatch(closeModal());
  });

  useServerEvent("approveConnection", () => {
    refetchConnections();
  });

  const timelineEvents = useMemo(
    () =>
      timelineData?.connection.timelineEvents.edges.map(({ node }) => node) ||
      [],
    [timelineData]
  );

  const connections = useMemo(
    () =>
      connectionData?.currentInmate?.connections.filter(
        (c) =>
          c.connectionStatus === ConnectionStatus.Active ||
          c.connectionStatus === ConnectionStatus.Inactive
      ) || [],
    [connectionData]
  );

  useEffect(() => {
    if (connections.length)
      setSelected(
        // give priority to active connections
        connections.find(
          (c) => c.connectionStatus === ConnectionStatus.Active
        ) || connections[0]
      );
  }, [connectionsLoading, connections]);

  const [createMessage, { loading: createLoading }] = useMutation(
    CreateMessageDocument,
    {
      update: (cache, { data }) => {
        const response = data?.createMessage;

        if (!response) return;
        const { message, event } = response;
        cache.modify({
          id: cache.identify({
            __typename: "Connection",
            id: message.connection.id,
          }),
          fields: {
            timelineEvents(existingEvents) {
              return {
                edges: [
                  ...existingEvents.edges,
                  { node: { ...event, object: message } },
                ],
              };
            },
          },
        });
        cache.modify({
          id: cache.identify({
            __typename: "Inmate",
            id: message.sender.id,
          }),
          fields: {
            hasSentAnyMessages: () => true,
          },
        });
        setHasSentAnyMessages(true);
      },
      onError: (err) =>
        reportApolloError(err, "InboxPage/CreateMessageDocument"),
    }
  );
  const [createVoiceCall, { loading: createVoiceCallLoading }] = useMutation(
    CreateVoiceCallDocument,
    {
      onError: (err) =>
        reportApolloError(err, `InboxPage/createVoiceCallError`),
      update: (cache, { data }) => {
        const response = data?.createVoiceCall;

        if (!response) return;
        const { meeting, event } = response;
        cache.modify({
          id: cache.identify({
            __typename: "Connection",
            id: event.connection.id,
          }),
          fields: {
            timelineEvents(existingEvents) {
              return {
                edges: [
                  ...existingEvents.edges,
                  { node: { ...event, object: meeting } },
                ],
              };
            },
          },
        });
      },
    }
  );

  useEffect(() => {
    client.cache.modify({
      id: client.cache.identify({
        __typename: "Connection",
        id: selected?.id,
      }),
      fields: {
        messages(existingRef) {
          return { ...existingRef, unreadMessageCount: 0 };
        },
      },
    });
    const newlyReadMessagesCount = selected?.messages.unreadMessageCount || 0;
    client.cache.modify({
      id: client.cache.identify({
        __typename: "Inmate",
        id: currentInmateId,
      }),

      fields: {
        unreadMessageCount(existingRef) {
          return existingRef - newlyReadMessagesCount;
        },
      },
    });
  }, [selected, currentInmateId]);

  const handleVoiceCallCreation = async (connection: TConnectionListItem) => {
    // open modal for call
    const vc = await createVoiceCall({
      variables: {
        input: {
          connectionId: connection.id,
        },
      },
    });

    if (!vc.data) return;

    dispatch(
      openModal({
        activeType: "VOICE_CALL_MODAL",
        entity: vc.data.createVoiceCall.meeting,
      })
    );
  };

  const eMessagingRestriction = selected?.activeRestrictions.find(
    (restriction) => restriction.service === FacilityService.Emessaging
  );

  const MessageInboxFooter = () => {
    if (!selected) return <div />;
    return (
      <div className="bg-gray-50 m-4">
        {selected?.connectionStatus === "INACTIVE" || eMessagingRestriction ? (
          <Alert
            type="warning"
            message={`${t("messaging:disabled.restriction", {
              firstName: selected.visitor.firstName,
              facilityPublicId:
                connectionData?.currentInmate?.facility.publicId || "",
            })} ${
              eMessagingRestriction?.expiresAt
                ? t("messaging:disabled.reenabled", {
                    date: formatDate(
                      new Date(eMessagingRestriction.expiresAt),
                      "weekdaymonthdaytimea"
                    ),
                  })
                : ""
            }`}
          />
        ) : (
          <MessageCompose
            loading={createLoading}
            initialValue={suggestedMessage}
            onSend={async (content) => {
              setSuggestedMessage("");
              const { errors } = await createMessage({
                variables: {
                  input: { connectionId: selected.id, content },
                },
              });
              if (
                !errors &&
                !hasSentAnyMessages &&
                firstMessageSentModalState === "neverOpened"
              ) {
                setFirstMessageSentModalState("open");
              }
            }}
          />
        )}
      </div>
    );
  };
  return (
    <Layout className="max-h-screen">
      <Layout.Header className="bg-white pl-8">Messages</Layout.Header>
      <Layout className="flex p-8">
        <FirstTimeUserModal
          isOpen={newUserIntroModalState === "open"}
          onClose={() => setNewUserIntroModalState("closed")}
        />
        <FirstMessageSentModal
          isOpen={firstMessageSentModalState === "open"}
          onClose={() => setFirstMessageSentModalState("closed")}
          recipientFirstName={selected?.visitor.firstName || ""}
        />
        {!connectionsLoading && !connections.length ? (
          <NoConnectionsView />
        ) : (
          <ConnectionListSidebar
            connections={connections}
            onSelect={(connection: TConnectionListItem) => {
              setSelected(connection);
              setSuggestedMessage("");
            }}
            selected={selected}
            className="shadow"
          />
        )}
        {selected && (
          <Layout className="bg-gray-50">
            <InboxHeader
              connection={selected}
              onCall={() => handleVoiceCallCreation(selected)}
              callIsLoading={createVoiceCallLoading}
            />
            <EventTimelineList
              events={timelineEvents}
              recipientFirstName={selected?.visitor.firstName || ""}
            />
            {!isTimelineLoading &&
              timelineEvents.filter(
                (e) =>
                  !(
                    e.object.__typename === "Connection" &&
                    e.verb === ConnectionTimelineEventVerb.Create
                  )
              ).length === 0 && (
                <NoMessagesView
                  suggestedMessage={suggestedMessage}
                  onSuggestedMessageSelect={(s: string) => {
                    setSuggestedMessage(s);
                  }}
                />
              )}
            <MessageInboxFooter />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default InboxPage;
