import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import { Message } from "connect-call-client";
import { format } from "date-fns";
import React from "react";
import { useAuthInfo } from "src/hooks/useAuthInfo";

interface Props {
  message: Message & { status: "sent" | "errored" };
  visitorFullName: string
  className?: string;
}

const MessageDisplay: React.FC<Props> = ({ message, visitorFullName, className }) => {
  const { authInfo } = useAuthInfo();
  const isMe = message.user.id === authInfo.id;
  const displayName =
    message.user.role === "monitor" ? "DOC" : isMe ? "You" : visitorFullName;

  return (
    <Space
      direction="vertical"
      align={isMe ? "end" : "start"}
      style={{ width: "100%" }}
      className={className}
    >
      <Space>
        <Typography.Text strong>{displayName}</Typography.Text>
        <Typography.Text type="secondary">
          {format(message.timestamp, "HH:mm")}
        </Typography.Text>
      </Space>
      <Typography.Text
        mark={message.user.role === "monitor"}
        className={isMe ? "float-right text-right" : "float-left"}
      >
        {message.contents}
      </Typography.Text>
      {message.status === "errored" && (
        <Space direction="horizontal">
          <ExclamationCircleOutlined className="text-red-600 text-xs" />
          <Typography.Text>Message failed to send</Typography.Text>
        </Space>
      )}
    </Space>
  );
};

export default MessageDisplay;
