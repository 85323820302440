import { Button, Space, Typography } from "antd";
import { ButtonProps, ButtonType } from "antd/lib/button";
import React from "react";

interface Props extends ButtonProps {
  icon: React.ReactNode;
  label?: string;
  onClick: () => void;
  type?: ButtonType;
  style?: React.CSSProperties;
  className?: string;
}

const IconButton = ({
  icon,
  label,
  onClick,
  type,
  style,
  className,
  ...props
}: Props) => {
  return (
    <Space direction="vertical" align="center">
      <Button
        icon={icon}
        onClick={onClick}
        type={type}
        style={style}
        className={`w-16 h-16 ${className || ""}`}
        {...props}
      />
      {label && <Typography.Text type="secondary">{label}</Typography.Text>}
    </Space>
  );
};

export default IconButton;
