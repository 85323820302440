import { useTranslation } from "react-i18next";

interface Props {}

const PrivacyNotice: React.FC<Props> = () => {
  const { t } = useTranslation("voiceCalls");
  return (
    <div>
      <div>
        <span className="text-xs text-black opacity-45">
          {t("startVoiceCallModal.PRIVACY NOTICE")}
        </span>
      </div>
      <div className="p-3 m-2 border border-gray-100">
        {t(
          "startVoiceCallModal.By participating in this call, I consent to the monitoring and recording of audio by the affiliated correctional facility__ I agree to abide by the facility’s stated calling policies and understand that failure to do so may result in the interruption of my call or revocation of calling abilities__"
        )}
      </div>
    </div>
  );
};

export default PrivacyNotice;
