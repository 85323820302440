import { useQuery } from "@apollo/client";
import { Modal } from "antd";
import { GetCallDocument } from "src/graphql/queries/GetCall.generated";
import { VoiceCallModalData } from "src/types/UI";
import { reportApolloError } from "src/utils";
import VoiceCallInterface from "../Inbox/VoiceCall/VoiceCallInterface";

interface Props {
  data: VoiceCallModalData;
  onCloseModal: () => void;
}

const VoiceCallModal = ({ data, onCloseModal }: Props) => {
  const callId = data.entity.id;

  // get latest information about the video handler
  const { data: callData } = useQuery(GetCallDocument, {
    variables: { meetingId: callId },
    fetchPolicy: "no-cache", // do not cache access token
    onError: (err) => reportApolloError(err, "VoiceCallModal/GetCallDocument"),
  });

  const vc = callData?.meeting;

  return (
    <Modal
      visible={true}
      cancelButtonProps={{
        style: { display: "none" },
      }}
      okButtonProps={{
        style: { display: "none" },
      }}
      onCancel={onCloseModal}
    >
      {vc && vc.call && vc.call.url && vc.call?.token && (
        <VoiceCallInterface vc={vc} onFinish={onCloseModal} />
      )}
    </Modal>
  );
};

export default VoiceCallModal;
