import { Button } from "antd";
import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { getVisitLabel } from "src/utils";
import { MeetingType } from "src/api/graphql";

interface Props {
  meetingTypeFilter: MeetingType | null;
  setMeetingTypeFilter: (type: MeetingType | null) => void;
  meetingType: MeetingType;
}

const Chip: React.FC<Props> = ({
  meetingTypeFilter,
  setMeetingTypeFilter,
  meetingType,
}) => {
  return (
    <>
      <Button
        type="text"
        style={{ paddingLeft: "0px" }}
        onClick={() =>
          meetingTypeFilter !== meetingType
            ? setMeetingTypeFilter(meetingType)
            : setMeetingTypeFilter(null)
        }
      >
        {getVisitLabel(meetingType)}
      </Button>
      {meetingTypeFilter === meetingType ? <CheckOutlined /> : null}
    </>
  );
};

export default Chip;
