import { useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Button } from "antd";
import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { MeetingStatus } from "src/api/graphql";
import { StreamDropType } from "src/components/Call/Call";
import CallFeedback from "src/components/CallFeedback";
import { CallExitType } from "src/components/CallFeedback/CallFeedback";
import Error from "src/components/Error";
import Loader from "src/components/Loader";
import { CreateCallReportDocument } from "src/graphql/mutations/CreateCallReport.generated";
import { GetMeetingDocument } from "src/graphql/queries/GetMeeting.generated";
import { useAppDispatch } from "src/redux";
import { enterFullScreen, exitFullScreen } from "src/redux/modules/UI";
import { hasMeetingExpired } from "src/utils";

interface PropsInterface extends RouteComponentProps {
  logout: Function;
  id: string;
  exitType: CallExitType;
  droppedStreams: StreamDropType;
}

const CallFeedbackPage: React.FC<PropsInterface> = (props) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation("error");

  const { exitType, id: meetingId, droppedStreams } = props;

  const { data, loading, error } = useQuery(GetMeetingDocument, {
    variables: { meetingId },
  });
  if (error) throw error;

  const [rateCall] = useMutation(CreateCallReportDocument, {
    onError: (e) => {
      Sentry.captureException(e);
    },
  });

  useEffect(() => {
    dispatch(enterFullScreen());

    return () => {
      dispatch(exitFullScreen());
    };
  }, [dispatch]);

  const call = data?.meeting;
  if (loading || !call) {
    return <Loader />;
  }

  if (
    call.status === MeetingStatus.PendingApproval ||
    call.status === MeetingStatus.Cancelled ||
    call.status === MeetingStatus.NoShow ||
    hasMeetingExpired(call)
  )
    return (
      <Error
        status="error"
        title={t("call.callNull")}
        extra={[
          <Button
            type="primary"
            size="large"
            onClick={() => dispatch(push("/"))}
          >
            {t("call.returnHome")}
          </Button>,
        ]}
      />
    );

  return (
    <CallFeedback
      call={call}
      rateCall={(rating: number, feedback?: string) =>
        rateCall({
          variables: {
            input: { meetingId: call.id, rating, description: feedback },
          },
        })
      }
      droppedStreams={droppedStreams}
      type={exitType}
      logout={() => props.logout()}
    />
  );
};

export default CallFeedbackPage;
