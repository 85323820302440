import React from "react";

const CallFeedbackWrapper: React.FC = ({ children }) => {
  return (
    <div className="flex flex-col items-center gap-4 my-8 mx-auto w-1/2">
      {children}
    </div>
  );
};

export default CallFeedbackWrapper;
