import { useTranslation } from "react-i18next";
import { ReactComponent as MailboxIllustration } from "src/assets/mailboxIllustration.svg";

interface Props {
  suggestedMessage: string;
  onSuggestedMessageSelect: (s: string) => void;
}
const NoMessagesView = (props: Props) => {
  const { suggestedMessage, onSuggestedMessageSelect } = props;
  const { t } = useTranslation("messaging");

  return (
    <div className="bg-gray-50 h-full flex flex-col justify-center items-center">
      <div className="flex flex-col mt-auto justify-center items-center text-black text-opacity-25">
        <MailboxIllustration />
        <div className="font-medium pb-2">{t("messages.noMessages.title")}</div>
        <div className="text-center w-3/4">{t("messages.noMessages.body")}</div>
      </div>

      <div className="mt-auto pl-5 pb-3 self-start flex gap-2 text-s">
        {!suggestedMessage &&
          [
            t("messages.noMessages.suggestionOne"),
            t("messages.noMessages.suggestionTwo"),
            t("messages.noMessages.suggestionThree"),
          ].map((message: string) => (
            <div
              className="bg-gray-100  rounded-2xl p-1 px-2 text-sm cursor-pointer"
              onClick={() => {
                onSuggestedMessageSelect(message);
              }}
              key={message}
            >
              <div className="opacity-45">{message}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default NoMessagesView;
