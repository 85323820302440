import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Result } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { client } from "./api/apollo";
import App from "./App";
import "./index.css";
import { history, Store } from "./redux";
import reportWebVitals from "./reportWebVitals";
import "./styles/tailwind.css";
import ServerEventsProvider from "./utils/ServerEventsProvider";

const ErrorFallback = (): JSX.Element => (
  <div className="w-screen h-screen flex items-center justify-center">
    <Result
      status="error"
      title="Sorry, something went wrong."
      subTitle="An unexpected error has occurred. Please report it to an Ameelio team member."
    />
  </div>
);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <ApolloProvider client={client}>
        <ServerEventsProvider>
          <ErrorBoundary fallback={<ErrorFallback />}>
            <App history={history} />
          </ErrorBoundary>
        </ServerEventsProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
