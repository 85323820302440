import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as Celebrate } from "src/assets/celebrate.svg";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  recipientFirstName: string;
}

const FirstMessageSentModal = ({
  onClose,
  isOpen,
  recipientFirstName,
}: Props) => {
  const { t } = useTranslation("messaging");
  return (
    <Modal
      title={<div className="h-8"></div>}
      visible={isOpen}
      onOk={onClose}
      onCancel={onClose}
      okText="OK"
      width={570}
      cancelButtonProps={{
        style: { display: "none" },
      }}
    >
      <div className="flex flex-col items-center">
        <Celebrate />
        <p className="font-bold">{t("messages.firstMessageSent.title")}</p>
        <p>{t("messages.firstMessageSent.body", { recipientFirstName })}</p>
      </div>
    </Modal>
  );
};

export default FirstMessageSentModal;
