import {
  AudioMutedOutlined,
  AudioOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Spin, Tooltip, Typography } from "antd";
import { Participant, useConnectCall } from "connect-call-client";
import { addMilliseconds, subMilliseconds } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BellOutlinedStrikethrough from "src/assets/Icons/BellOutlinedStrikethrough";
import PhoneFaceDown from "src/assets/Icons/PhoneFaceDown";
import JoinCallSound from "src/assets/Sounds/EnterCall.wav";
import LeaveCallSound from "src/assets/Sounds/LeaveCall.wav";
import AvatarGroup from "src/components/Avatar/AvatarGroup";
import Audio from "src/components/Call/Audio";
import IconButton from "src/components/Common/Buttons/IconButton";
import { Timer, useTimer } from "src/components/Common/Timer";
import { CreateCallReportDocument } from "src/graphql/mutations/CreateCallReport.generated";
import { EndVoiceCallDocument } from "src/graphql/mutations/EndVoiceCall.generated";
import { GetCallQuery } from "src/graphql/queries/GetCall.generated";
import { useAuthInfo } from "src/hooks/useAuthInfo";
import {
  getFirstNames,
  getFullNames,
  MAX_VOICE_CALL_DURATION_PERIOD_MINUTES,
  millisecondsToHoursMinutesSecondsTextLabel,
  reportApolloError,
} from "src/utils";
import { useServerEvent } from "src/utils/ServerEventsProvider";
import useSound from "use-sound";
import VoiceCallRatingCard, { CALL_END_REASON } from "./VoiceCallRatingCard";
type VoiceCall = GetCallQuery["meeting"];

interface Props {
  vc: VoiceCall;
  onFinish: () => void;
}

const VoiceCallInterface = ({ vc, onFinish }: Props) => {
  const { t } = useTranslation(["voiceCalls", "call", "feedback"]);
  const [playJoinCall] = useSound(JoinCallSound);
  const [playLeaveCall] = useSound(LeaveCallSound);
  const { authInfo } = useAuthInfo();
  const [hasTimeAlert, setHasTimeAlert] = useState(true);
  const [renderState, setRenderState] = useState<
    "calling" | "live" | "missed" | "ended"
  >("calling");
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [callEndReason, setCallEndReason] =
    useState<CALL_END_REASON>("disconnect");
  const disconnectRef = useRef<() => Promise<void>>();
  const [
    endVoiceCall,
    { loading: endVoiceCallLoading, data: endVoiceCallResult },
  ] = useMutation(EndVoiceCallDocument, {
    onError: (err) => reportApolloError(err, "InboxPage/EndVoiceCallDocument"),
    onCompleted: () => disconnectRef.current && disconnectRef.current(),
  });
  const [rateCall] = useMutation(CreateCallReportDocument, {
    onError: (err) =>
      reportApolloError(err, "InboxPage/CreateCallReportDocument"),
  });
  const { start: startTimer, value: timerValue } = useTimer();

  const handlePeerConnected = useCallback(() => {
    playJoinCall();
    setRenderState("live");
  }, [playJoinCall]);

  const handlePeerDisconnected = useCallback(
    (participant: Participant) => {
      if (participant.detail === "connection_error") {
        setCallEndReason("peerError");
      } else {
        setCallEndReason("peerDisconnect");
      }
      setRenderState("ended");
      endVoiceCall({
        variables: {
          input: {
            meetingId: vc.id,
          },
        },
      });
      playLeaveCall();
    },
    [playLeaveCall, endVoiceCall, vc.id]
  );

  const handleTimer = (
    name: string,
    msRemaining: number,
    msElapsed: number
  ) => {
    setEndTime(addMilliseconds(Date.now(), msRemaining));
    setStartTime(subMilliseconds(Date.now(), msElapsed));
    startTimer(Math.floor(msElapsed / 1000));
  };

  const { status, produceTrack, localAudio, toggleAudio, peers, disconnect } =
    useConnectCall({
      call: {
        id: vc.id,
        url: vc.call!.url!,
        token: vc.call!.token!,
      },
      user: authInfo,
      onPeerConnected: handlePeerConnected,
      onPeerDisconnected: handlePeerDisconnected,
      onTimer: handleTimer,
    });
  disconnectRef.current = disconnect;
  useEffect(() => {
    switch (status) {
      case "connected":
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => produceTrack(stream.getAudioTracks()[0]));
        break;
      case "live":
        break;
      case "terminated":
        setCallEndReason("terminated");
        endVoiceCall({
          variables: {
            input: {
              meetingId: vc.id,
            },
          },
        });
        setRenderState("ended");
        break;
      case "ended":
        if (endTime && endTime.getTime() - Date.now() < 0) {
          setCallEndReason("timeUp");
          setRenderState("ended");
        } else {
          setRenderState("missed");
          setTimeout(onFinish, 2000);
        }
        endVoiceCall({
          variables: {
            input: {
              meetingId: vc.id,
            },
          },
        });
        break;
      default:
        break;
    }
  }, [t, status, vc, produceTrack, endVoiceCall, endTime, startTime, onFinish]);

  const peer = peers[0];
  const remoteAudioTracks = peer?.stream.getAudioTracks().length;
  const isPeerMuted =
    remoteAudioTracks === undefined || remoteAudioTracks > 0 ? false : true;

  useServerEvent("voiceCallDeclined", (data) => {
    if (data.id === vc.id) {
      setRenderState("missed");
      setTimeout(onFinish, 2000);
    }
  });

  const handleLeaveCall = () => {
    endVoiceCall({
      variables: {
        input: {
          meetingId: vc.id,
        },
      },
    });
    setCallEndReason("disconnect");
    if (renderState === "calling") {
      onFinish();
    } else {
      setRenderState("ended");
    }
  };

  // collect feedback
  if (renderState === "ended") {
    return (
      <VoiceCallRatingCard
        onSubmit={({ rating, feedback }) => {
          rateCall({
            variables: {
              input: { meetingId: vc.id, rating, description: feedback },
            },
          });
          onFinish();
        }}
        minutesTalked={millisecondsToHoursMinutesSecondsTextLabel(
          endVoiceCallResult?.endVoiceCall.meeting.call?.duration || 0
        )}
        callEndReason={callEndReason}
        visitorFirstName={getFirstNames(vc.visitors)}
        facilityPublicId={
          endVoiceCallResult?.endVoiceCall.meeting.facility.publicId || ""
        }
        callResetMinutes={
          MAX_VOICE_CALL_DURATION_PERIOD_MINUTES -
          Math.floor(
            (endVoiceCallResult?.endVoiceCall.meeting.facility
              .maxVoiceCallDuration || 0) /
              (60 * 1000)
          )
        }
      />
    );
  }
  if (renderState === "missed")
    return (
      <div className="flex item-center justify-center flex-col text-center gap-4 pt-16">
        <AvatarGroup
          visitors={vc.visitors}
          size={80}
          className="justify-center"
        />
        <div>
          <Typography.Text type="secondary" className="text-center">
            {getFullNames(vc.visitors)}
          </Typography.Text>
        </div>
        <div className="flex gap-2 justify-center items-center">
          <Typography.Text className="text-lg font-semibold">
            {t("voiceCalls:voiceCallModal.missed")}
          </Typography.Text>
        </div>
      </div>
    );

  return (
    // render state is presumed "live"
    <div className="flex item-center justify-center flex-col text-center gap-4">
      <span className="text-center">
        {t(
          "voiceCalls:voiceCallModal.All calls are monitored and recorded by the facility for security purposes"
        )}
      </span>
      {peer && <Audio autoPlay={true} srcObject={peer.stream} />}

      <AvatarGroup
        visitors={vc.visitors}
        size={80}
        className="justify-center"
      />
      <div>
        <Typography.Text type="secondary" className="text-center">
          {getFullNames(vc.visitors)}{" "}
          {isPeerMuted && (
            <AudioMutedOutlined className="text-red-500 align-baseline" />
          )}
        </Typography.Text>
      </div>
      <div className="flex flex-col items-center">
        {endTime != null && (
          <div>
            {
              <Timer
                endTime={endTime.getTime()}
                playAudio={true}
                timerValue={timerValue}
              />
            }
          </div>
        )}
      </div>
      {renderState === "calling" && (
        <div className="flex gap-2 justify-center items-center">
          <Typography.Text className="font-bold">
            {t("voiceCalls:voiceCallModal.Calling")}...
          </Typography.Text>
          <Spin size="small" />
        </div>
      )}
      <div className="justify-center flex gap-10 mt-4">
        <IconButton
          icon={
            !localAudio || localAudio.paused ? (
              <AudioMutedOutlined className="text-2xl" />
            ) : (
              <AudioOutlined className="text-2xl" />
            )
          }
          type={"default"}
          onClick={toggleAudio}
          shape="circle"
          label={`${t("call:videoOverlay.mic")}: ${
            localAudio?.paused
              ? t("call:videoOverlay.OFF")
              : t("call:videoOverlay.ON")
          }`}
        />
        <Tooltip
          title={
            hasTimeAlert
              ? t("call:videoOverlay.timeAlert.tooltipAlertOn")
              : t("call:videoOverlay.timeAlert.tooltipAlertOff")
          }
        >
          <IconButton
            icon={
              hasTimeAlert ? (
                <BellOutlined className="text-2xl" />
              ) : (
                <div className="flex flex-col items-center">
                  <BellOutlinedStrikethrough />
                </div>
              )
            }
            type={hasTimeAlert ? "default" : "default"}
            ghost={hasTimeAlert}
            shape="circle"
            onClick={() => setHasTimeAlert((timeAlert) => !timeAlert)}
            label={`${t("call:videoOverlay.timeAlert.label")}: ${
              hasTimeAlert
                ? t("call:videoOverlay.ON")
                : t("call:videoOverlay.OFF")
            }`}
            className={`mx-6 ${
              hasTimeAlert
                ? "text-black	opacity-85 border-gray-300 "
                : "text-blue-600 border-blue-600"
            }`}
          />
        </Tooltip>

        <IconButton
          size="large"
          className="bg-red-500"
          onClick={handleLeaveCall}
          label={`${t("call:videoOverlay.end")}`}
          danger
          icon={
            <div className="flex flex-col items-center">
              <PhoneFaceDown />
            </div>
          }
          disabled={endVoiceCallLoading}
          loading={endVoiceCallLoading}
          shape="circle"
        />
      </div>
    </div>
  );
};

export default VoiceCallInterface;
