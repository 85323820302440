import { push } from "connected-react-router";
import { useCallback } from "react";
import { useAppDispatch } from "src/redux";

export function useAppNavigate(): (path: string) => void {
  const dispatch = useAppDispatch();

  const navigate = useCallback(
    (path: string) => {
      dispatch(push(path));
    },
    [dispatch]
  );
  return navigate;
}
