import { configureStore } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createHashHistory, History } from "history";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers } from "redux";
import { modalsSlice } from "src/redux/modules/modalsSlice";
import { sessionReducer } from "./modules/session";
import { UISlice } from "./modules/UI";

export const history = createHashHistory();

export const createRootReducer = (history: History) =>
  combineReducers({
    ui: UISlice.reducer,
    session: sessionReducer,
    modals: modalsSlice.reducer,
    router: connectRouter(history),
  });
export const rootReducer = createRootReducer(history);

export const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(history)),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof Store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
