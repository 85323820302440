import { Button, Card } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { hasIncompleteCallRating } from "src/utils";
import CallRate from "./CallRate";

interface Props {
  onSubmit: (payload: { rating: number; feedback?: string }) => void;
  allowRejoin?: boolean;
}

const VideoCallRatingCard = ({ onSubmit, allowRejoin }: Props) => {
  const { t } = useTranslation(["feedback", "common"]);

  const [rating, setRating] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>();
  const hasIncompleteRating = hasIncompleteCallRating(rating, feedback);
  const FEEDBACK_OPTIONS: string[] = [
    t("feedback:qualityRating.review.audioLow"),
    t("feedback:qualityRating.review.cantSeeHear"),
    t("feedback:qualityRating.review.cantHearThem"),
    t("feedback:qualityRating.review.freezing"),
    t("feedback:qualityRating.review.none"),
  ];

  return (
    <div className="grid gap-4 items-center justify-center">
      <Card className="shadow-lg w-96">
        <CallRate
          rating={rating}
          feedback={feedback}
          onChangeFeedback={setFeedback}
          onChangeRating={setRating}
          feedbackOptions={FEEDBACK_OPTIONS}
        />
      </Card>

      <div className="flex gap-4 justify-center">
        {
          <Button
            size="large"
            disabled={hasIncompleteRating}
            onClick={() => onSubmit({ rating, feedback })}
          >
            {allowRejoin ? t("feedback:buttons.rejoin") : t("common:logout")}
          </Button>
        }

        <Button
          type="primary"
          size="large"
          disabled={hasIncompleteRating}
          onClick={() => onSubmit({ rating, feedback })}
        >
          {t("feedback:buttons.return")}
        </Button>
      </div>
    </div>
  );
};

export default VideoCallRatingCard;
