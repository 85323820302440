import { SendOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { showToast } from "src/utils";

interface Props {
  onSend: (content: string) => Promise<void>;
  className?: string;
  loading?: boolean;
  initialValue?: string;
}

const MessageCompose = ({
  onSend,
  className,
  loading,
  initialValue,
}: Props) => {
  const [draft, setDraft] = useState(initialValue || "");
  const { t } = useTranslation(["call", "common", "messaging"]);

  const handleSend = async () => {
    const cleanMessage = draft.trim();
    if (!cleanMessage.length) return;
    await onSend(cleanMessage);
    setDraft("");
  };

  return (
    <div className={`flex items-center gap-4 ${className || ""}`}>
      <Input.TextArea
        value={draft}
        rows={1}
        onChange={(e) => {
          const { value } = e.target;
          const clean = value.replace(
            // eslint-disable-next-line no-control-regex
            /[^\x00-\x7F|àèòùáéíóúñÑÁÉÍÓÚ¿¡ôêû]/g,
            ""
          );
          if (clean.length !== value.length) {
            showToast(
              "MessageCompose",
              t("messaging:messages.errors.emojiNotAllowed"),
              "warning"
            );
          }
          setDraft(clean);
        }}
        onPressEnter={handleSend}
        onSubmit={handleSend}
        placeholder={t("call:chat.placeholder")}
        autoFocus
        className="bg-white"
      />
      <Button
        type="primary"
        icon={<SendOutlined className="align-baseline" />}
        onClick={handleSend}
        loading={loading}
        disabled={loading}
      >
        {t("common:verbs.send")}
      </Button>
    </div>
  );
};

export default MessageCompose;
