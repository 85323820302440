import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { SentryLink } from "apollo-link-sentry";
import { onError } from "@apollo/client/link/error";
import { API_HOST } from "./Common";

const httpLink = createHttpLink({
  uri: `${API_HOST}/api/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const response = sessionStorage.getItem("token");
  const token = response ? JSON.parse(response) : "";
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        // Doing this the best way will require a major refactor of the top-level of the app
        // so for now we're doing it a less pretty way
        if (err.message.includes("API token is expired or revoked")) {
          sessionStorage.clear();
          window.location.reload();
          window.alert("Your session expired. Please login again.");
          return;
        }
      }
    }
    return forward(operation);
  }
);

export const client = new ApolloClient({
  link: ApolloLink.from([
    new SentryLink({
      attachBreadcrumbs: {
        includeError: true,
      },
    }),
    errorLink,
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Connection: {
        fields: {
          messages: {
            merge: true,
          },
        },
      },
    },
  }),
});
