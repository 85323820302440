import { useQuery } from "@apollo/client";
import { FacilityFeature } from "src/api/graphql";
import { GetFacilityServicesDocument } from "src/graphql/queries/GetFacilityServices.generated";

const useFacilityFeature = (feature: FacilityFeature) => {
  const { data: facilityServicesData } = useQuery(GetFacilityServicesDocument, {
    fetchPolicy: "cache-first",
  });
  const features = facilityServicesData?.currentInmate?.facility?.features;

  return features?.includes(feature);
};

export default useFacilityFeature;
