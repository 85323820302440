import { Button, Card } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CallRate from "src/components/CallFeedback/CallRate";
import { hasIncompleteCallRating } from "src/utils";

export type CALL_END_REASON =
  | "disconnect"
  | "peerDisconnect"
  | "peerError"
  | "timeUp"
  | "terminated";

type Props = {
  onSubmit: (payload: { rating: number; feedback?: string }) => void;
  minutesTalked: string;
  callEndReason: CALL_END_REASON;
  visitorFirstName: string;
  facilityPublicId: string;
  callResetMinutes: number;
};

const VoiceCallRatingCard = ({
  onSubmit,
  minutesTalked,
  callEndReason,
  visitorFirstName,
  facilityPublicId,
  callResetMinutes,
}: Props) => {
  const { t } = useTranslation(["feedback", "common", "voiceCalls"]);

  const [rating, setRating] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>();
  const hasIncompleteRating = hasIncompleteCallRating(rating, feedback);

  const FEEDBACK_OPTIONS: string[] = [
    t("feedback:qualityRating.review.micWasNotWorking"),
    t("feedback:qualityRating.review.cantHearThem"),
    t("feedback:qualityRating.review.callChoppy"),
    t("feedback:qualityRating.review.none"),
  ];

  const callEndText = (() => {
    switch (callEndReason) {
      case "disconnect":
        return t("voiceCalls:voiceCallInterface.You ended the voice call");
      case "peerDisconnect":
        return t(
          "voiceCalls:voiceCallInterface.{{visitorFirstName}} ended the voice call",
          { visitorFirstName }
        );
      case "terminated":
        return t(
          "voiceCalls:voiceCallInterface.{{facilityPublicId}} terminated your call__ That’s all we know__ We apologize for the inconvenience__",
          { facilityPublicId }
        );
      case "timeUp":
        return t(
          "voiceCalls:voiceCallInterface.You really made the most of your time! If you have more voice call minutes left this week, you can make another call in {{callResetMinutes}} minutes__",
          { callResetMinutes }
        );
      case "peerError":
        return t(
          "voiceCalls:voiceCallInterface.Your call got disconnected because someone’s signal was unstable__ We apologize for the inconvenience__"
        );
      default:
        return "";
    }
  })();

  return (
    <div className="m-8">
      <Card>
        <div className="flex flex-col gap-4 items-center justify-center">
          <div className="text-base">{callEndText}</div>
          <div className="text-sm text-gray-500">Minutes talked</div>
          <div className="text-2xl">{minutesTalked}</div>
          <CallRate
            rating={rating}
            feedback={feedback}
            onChangeFeedback={setFeedback}
            onChangeRating={setRating}
            feedbackOptions={FEEDBACK_OPTIONS}
          />
          <div>
            <Button
              onClick={() => onSubmit({ rating, feedback })}
              disabled={hasIncompleteRating}
            >
              {t("voiceCalls:voiceCallInterface.Submit Feedback")}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default VoiceCallRatingCard;
