import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { FacilityService } from "src/api/graphql";
import { formatDate } from "src/utils";

interface Props {
  connectionFirstName: string;
  service: FacilityService;
  createdAt: number;
  expiresAt: number | null;
}

const ServiceNames: Record<FacilityService, string> = {
  [FacilityService.VideoCall]: "Video calls",
  [FacilityService.InPersonVisit]: "In-person visits",
  [FacilityService.Emessaging]: "Messaging",
  [FacilityService.VoiceCall]: "Voice Call",
  [FacilityService.Webinar]: "Webinar",
};

const ConnectionRestrictionCard = ({
  service,
  createdAt,
  expiresAt,
}: Props) => {
  const serviceName = ServiceNames[service];
  const enabled = !!expiresAt && createdAt > expiresAt;

  return (
    <Divider dashed className="pt-3 opacity-45">
      <div className=" text-xs text-black flex flex-row justify-center items-center">
        <div className="pr-1">
          {enabled ? (
            <UnlockOutlined className="align-baseline" />
          ) : (
            <LockOutlined className="align-baseline" />
          )}
        </div>
        <div>
          {serviceName}{" "}
          {enabled
            ? `enabled`
            : `disabled ${
                expiresAt
                  ? `until ${formatDate(new Date(expiresAt), "monthdayyear")}`
                  : "indefinitely"
              }  `}
          {" • "}
          {formatDate(new Date(createdAt), "monthdayyear")}
        </div>
      </div>
    </Divider>
  );
};

export default ConnectionRestrictionCard;
