import { Button, Space, Table, Typography } from "antd";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MeetingType } from "src/api/graphql";
import { ReactComponent as PeopleGray } from "src/assets/peopleGray.svg";
import { ReactComponent as VideoCameraGray } from "src/assets/videoCameraGray.svg";
import { useAppDispatch } from "src/redux";
import { openModal } from "src/redux/modules/modalsSlice";
import { Meeting } from "src/types/Meeting";
import { getFullName, getVisitLabel } from "src/utils";
import IndividualAvatar from "../Avatar/IndividualAvatar";

interface Props {
  meetings: Meeting[];
  meetingTypeFilter: MeetingType | null;
  selectedVisitorsFilter: string[] | null;
  setSelectedMeeting: (meeting: Meeting | null) => void;
  loading: boolean;
}

const MeetingsTable: React.FC<Props> = ({
  meetings,
  meetingTypeFilter,
  selectedVisitorsFilter,
  setSelectedMeeting,
  loading,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("calls");
  const [filteredVisits, setFilteredVisits] = useState<Meeting[]>(meetings);

  const getIcon = (visitType: MeetingType) => {
    switch (visitType) {
      case MeetingType.VideoCall:
        return <VideoCameraGray />;
      case MeetingType.InPersonVisit:
        return <PeopleGray />;
      default:
        return <VideoCameraGray />;
    }
  };

  useEffect(() => {
    setFilteredVisits(() => {
      return meetings.filter((meeting) => {
        let isOk = true;
        if (meetingTypeFilter) {
          isOk = meeting.meetingType === meetingTypeFilter;
        }
        if (selectedVisitorsFilter) {
          const name = `${meeting.visitors[0].firstName} ${meeting.visitors[0].lastName}`;
          isOk = selectedVisitorsFilter.includes(name);
        }
        return isOk;
      });
    });
  }, [meetings, meetingTypeFilter, selectedVisitorsFilter]);

  const tableColumns = [
    {
      title: "Date",
      key: "date",
      render: (meeting: Meeting) => {
        return (
          <Typography.Text>
            {format(new Date(meeting.interval.startAt), "eeee, LLLL d")}
          </Typography.Text>
        );
      },
    },
    {
      title: "Time",
      key: "time",
      render: (meeting: Meeting) => {
        const timeZone = format(new Date(meeting.interval.startAt), "z");
        return (
          <Typography.Text>
            {`${format(new Date(meeting.interval.startAt), "h:mm bbb")} -${
              meeting.interval.endAt
                ? ` ${format(new Date(meeting.interval.endAt), "h:mm bbb")}`
                : ""
            } ${timeZone}`}
          </Typography.Text>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      render: (meeting: Meeting) => {
        return (
          <Space align="center">
            {getIcon(meeting.meetingType)}
            <Typography.Text>
              {getVisitLabel(meeting.meetingType)}
            </Typography.Text>
          </Space>
        );
      },
    },
    {
      title: "Participants",
      key: "visitors",
      render: (meeting: Meeting) => {
        return (
          <Space direction="vertical">
            {meeting.visitors.map((v) => (
              <Space key={v.id}>
                <IndividualAvatar
                  src={v.profileImageUrl || undefined}
                  size={24}
                  fallback={getFullName(v)}
                />
                <Typography.Text>{getFullName(v)}</Typography.Text>
              </Space>
            ))}
          </Space>
        );
      },
    },
    {
      title: "",
      key: "view-details",
      render: (meeting: Meeting) => (
        <Button type="link" onClick={() => setSelectedMeeting(meeting)}>
          View details
        </Button>
      ),
    },
    {
      title: "",
      key: "cancel",
      render: (meeting: Meeting) => (
        <Button
          type="link"
          onClick={() =>
            dispatch(
              openModal({ activeType: "CANCEL_MEETING_MODAL", entity: meeting })
            )
          }
        >
          {t("cancel")}
        </Button>
      ),
    },
  ];

  return (
    <Table
      className="w-full"
      columns={tableColumns}
      dataSource={filteredVisits}
      rowKey={"id"}
      loading={loading}
    />
  );
};

export default MeetingsTable;
