import { useQuery } from "@apollo/client";
import { Alert, Button, Layout } from "antd";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import CyclingLottie from "src/assets/Lottie/GirlCycling.json";
import GoodbyeLottie from "src/assets/Lottie/Goodbye.json";
import RocketLottie from "src/assets/Lottie/Rocket.json";
import ShrugLottie from "src/assets/Lottie/Shrug.json";
import TeamWaveLottie from "src/assets/Lottie/TeamWave.json";
import { GetMyFacilityNameDocument } from "src/graphql/queries/GetMyFacilityName.generated";
import { useAppNavigate } from "src/hooks/useAppNavigate";
import { Call } from "src/types/Meeting";
import { getFirstNames, getRandomItem, showToast } from "src/utils";
import { getLottieOptions } from "src/utils/UI";
import { StreamDropType } from "../Call/Call";
import { H2 } from "../Typography";
import CallFeedbackWrapper from "./CallFeedbackWrapper";
import VideoCallRatingCard from "./VideoCallRatingCard";

/*
  No show: other participant didn't join at all
  Early exit: the incarcerated user left the call
  Ended: 'call_status' socket event. Call naturally expired.
  Terminated: 'call_status' socket event. DOC terminated the call.
*/
export type CallExitType = "no_show" | "early_exit" | "ended" | "terminated";

interface Props {
  call: Pick<Call, "id" | "visitors">;
  rateCall: (rating: number, feedback?: string) => Promise<unknown>;
  droppedStreams: StreamDropType;
  type: CallExitType | undefined;
  logout: () => void;
}

const FeedbackLottie = ({ lottie }: { lottie: Record<string, any> }) => (
  <Lottie
    {...getLottieOptions(lottie)}
    style={{ height: "30%", width: "30%" }}
  />
);

const HAPPY_LOTTIES = [
  RocketLottie,
  CyclingLottie,
  TeamWaveLottie,
  GoodbyeLottie,
];

const NoShowFeedback = ({
  participantNames,
  onReturn,
}: {
  participantNames: string;
  onReturn: () => void;
}) => {
  const { t } = useTranslation(["feedback", "common"]);
  return (
    <CallFeedbackWrapper>
      <FeedbackLottie lottie={ShrugLottie} />
      <H2 bold className="text-center">
        {t("feedback:title.no_show", {
          names: participantNames,
        })}
      </H2>
      <span className="text-center">
        {t("feedback:body.no_show", {
          names: participantNames,
        })}
      </span>
      <Button type="primary" size="large" onClick={onReturn}>
        {t("feedback:buttons.return")}
      </Button>
    </CallFeedbackWrapper>
  );
};

const BadCallStyle = {
  borderColor: "#C8E2F6",
  backgroundColor: "#F7FBFD",
};

const CallFeedback: React.FC<Props> = ({
  call,
  rateCall,
  droppedStreams,
  type,
  logout,
}) => {
  const { t } = useTranslation(["feedback", "common"]);

  const navigate = useAppNavigate();

  const { data: facilityNameData } = useQuery(GetMyFacilityNameDocument);

  const badCallDescription = useMemo(() => {
    switch (droppedStreams) {
      case "local":
        return t("feedback:badCall.local", {
          facilityName:
            facilityNameData?.currentInmate?.facility.name || "facility",
        });
      case "peer":
        return t("feedback:badCall.peer", {
          peerFirstName: getFirstNames(call.visitors),
        });
      case "localAndPeer":
        return t("feedback:badCall.localAndPeer", {
          facilityName:
            facilityNameData?.currentInmate?.facility.name || "facility",
        });
    }
  }, [droppedStreams, facilityNameData, call, t]);

  const ExitFeedback = () => {
    switch (type) {
      case "no_show":
        return (
          <NoShowFeedback
            participantNames={getFirstNames(call.visitors)}
            onReturn={() => navigate("/")}
          />
        );
      case "ended":
      case "early_exit":
      case "terminated":
      default:
        return (
          <CallFeedbackWrapper>
            <FeedbackLottie lottie={getRandomItem(HAPPY_LOTTIES)} />
            <H2>
              {type === "ended" || type === "terminated"
                ? t("feedback:title.happy", {
                    names: getFirstNames(call.visitors),
                  })
                : t("feedback:title.early_exit")}
            </H2>
            {droppedStreams !== "none" && (
              <Alert
                className="w-96"
                style={BadCallStyle}
                message={t("feedback:badCall.sorry")}
                description={badCallDescription}
              ></Alert>
            )}
            <VideoCallRatingCard
              onSubmit={async ({ rating, feedback }) => {
                await rateCall(rating, feedback);
                if (type === "early_exit") {
                  navigate(`/call/${call.id}`);
                } else {
                  logout();
                }
                showToast(
                  "useCallRating/handleSubmit",
                  t("feedback:qualityRating.submissionFeedback"),
                  "success"
                );
              }}
              allowRejoin={type === "early_exit"}
            />
          </CallFeedbackWrapper>
        );
    }
  };

  return (
    <Layout.Content className="flex w-screen h-screen bg-white">
      <ExitFeedback />
    </Layout.Content>
  );
};

export default CallFeedback;
