import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MeetingFragmentFragmentDoc } from '../fragments/MeetingFragment.generated';
import { VisitorFragmentFragmentDoc } from '../fragments/VisitorFragment.generated';
import { CallFragmentFragmentDoc } from '../fragments/CallFragment.generated';
export type CreateVoiceCallMutationVariables = Types.Exact<{
  input: Types.CreateVoiceCallInput;
}>;


export type CreateVoiceCallMutation = { __typename?: 'Mutation', createVoiceCall: { __typename?: 'CreateVoiceCallResponse', event: { __typename?: 'ConnectionTimelineEvent', id: string, connection: { __typename?: 'Connection', id: string } }, meeting: { __typename?: 'Meeting', id: string, meetingType: Types.MeetingType, status: Types.MeetingStatus, isCisco: boolean, participantComment: Types.ParticipantComment | null, visitors: Array<{ __typename?: 'Visitor', id: string, firstName: string, lastName: string, email: string, profileImageUrl: string | null }>, call: { __typename?: 'Call', status: Types.CallStatus, token: string | null, url: string | null, duration: number | null, ciscoJWT: string | null } | null, interval: { __typename?: 'Interval', startAt: number, endAt: number, seconds: number | null } } } };


export const CreateVoiceCallDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateVoiceCall"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateVoiceCallInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createVoiceCall"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"event"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"connection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"meeting"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MeetingFragment"}},{"kind":"Field","name":{"kind":"Name","value":"visitors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VisitorFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"call"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CallFragment"}}]}}]}}]}}]}},...MeetingFragmentFragmentDoc.definitions,...VisitorFragmentFragmentDoc.definitions,...CallFragmentFragmentDoc.definitions]} as unknown as DocumentNode<CreateVoiceCallMutation, CreateVoiceCallMutationVariables>;