import { Language } from "src/types/Session";

export const AVATAR_LARGE = 135;

export const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const TOKEN_KEY = "apiToken";

export const WRAPPER_PADDING = { padding: 24, paddingTop: 0 };

export const LANGUAGES: Record<Language, string> = {
  en: "English",
  es: "Español",
};
