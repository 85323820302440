import { useQuery } from "@apollo/client";
import React from "react";
import { MeetingStatus } from "src/api/graphql";
import Meetings from "src/components/Meetings";
import { GetMeetingsDocument } from "src/graphql/queries/GetMeetings.generated";
import "src/i18n/config";

const MeetingsPage: React.FC = () => {
  const { data, loading, error } = useQuery(GetMeetingsDocument);
  if (error) throw error;

  const meetings = (
    data?.currentInmate?.meetings.edges.map((e) => e.node) || []
  )
    .filter(
      (m) =>
        m.status === MeetingStatus.Live || m.status === MeetingStatus.Scheduled
    )
    .sort(
      (a, b) =>
        new Date(a.interval.startAt).getTime() -
        new Date(b.interval.startAt).getTime()
    );

  return <Meetings meetings={meetings} loading={loading} />;
};

export default MeetingsPage;
