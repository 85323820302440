import { Col, Space, Typography } from "antd";
import React from "react";
import { VisitorFragmentFragment } from "src/graphql/fragments/VisitorFragment.generated";
import { getFullName } from "src/utils/utils";
import IndividualAvatar from "../Avatar/IndividualAvatar";

interface Props {
  visitor: VisitorFragmentFragment;
}

const ConnectionItem: React.FC<Props> = ({ visitor }) => {
  return (
    <Col key={visitor.id} className="flex flex-col align-center">
      <Space direction="vertical">
        <IndividualAvatar
          size={80}
          src={visitor.profileImageUrl || undefined}
          fallback={getFullName(visitor)}
        />
        <div>
          <div>
            <Typography.Text>{getFullName(visitor)}</Typography.Text>
          </div>
        </div>
      </Space>
    </Col>
  );
};

export default ConnectionItem;
