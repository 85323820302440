import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Col, Modal, Row } from "antd";
import React, { useState } from "react";
import { UpdateInmateDocument } from "src/graphql/mutations/UpdateInmate.generated";
import { useAuthInfo } from "src/hooks/useAuthInfo";
import { ProfilePhotoModalData } from "src/types/UI";
import { showToast } from "src/utils";

interface Props {
  data: ProfilePhotoModalData;
  onClose: () => void;
}

const ProfilePhotoModal: React.FC<Props> = ({ data, onClose: closeModal }) => {
  const photos = data.entity;
  const [selected, setSelected] = useState<string>();
  const { authInfo } = useAuthInfo();

  const [updateProfile] = useMutation(UpdateInmateDocument, {
    onError: (e) => {
      Sentry.captureException(e);
      showToast("update_profile_error", "Failed to update profile", "error");
    },
  });

  return (
    <Modal
      onCancel={closeModal}
      onOk={() => {
        if (selected) {
          updateProfile({
            variables: {
              input: {
                inmateId: authInfo.id,
                profileImageUrl: selected,
              },
            },
          });
        }
      }}
      visible={true}
    >
      <Row gutter={[16, 16]} className="overflow-y-scroll	max-h-96">
        {photos.map((photo) => (
          <Col span={8}>
            <img
              src={photo}
              className={selected === photo ? "border-2 border-blue-500	" : ""}
              onClick={() => setSelected(photo)}
              alt={"Profile option"}
            />
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default ProfilePhotoModal;
