import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "socket.io-client";
import { API_HOST } from "src/api/Common";

type ServerToClientEvents = {
  updateConnectionTimeline: { id: string };
  approveConnection: { id: string };
  voiceCallDeclined: { id: string };
};

// TODO: Socket<ServerToClientEvents, ClientToServerEvents> in v4
type TypedSocket = {
  /** register an event listener */
  on: <N extends keyof ServerToClientEvents>(
    event: N,
    fn: (data: ServerToClientEvents[N]) => void
  ) => void;
  /** remove an event listener */
  off: <N extends keyof ServerToClientEvents>(
    event: N,
    fn: (data: ServerToClientEvents[N]) => void
  ) => void;
};

const MockClient: TypedSocket = {
  on: () => null,
  off: () => null,
};

type ServerEventsContext = {
  client: TypedSocket;
};

const ReactContext = React.createContext<ServerEventsContext>({
  client: MockClient,
});

const ServerEventsProvider: React.FC = ({ children }) => {
  // initialize a connected client
  const [client, setClient] = useState<TypedSocket>(MockClient);
  const response = sessionStorage.getItem("token");
  const token = response ? JSON.parse(response) : "";
  useEffect(() => {
    if (!token) return;

    const socket = connect(API_HOST, {
      transports: ["websocket"],
      auth: {
        token: token.replace("Bearer ", ""),
      },
    });
    socket.on("connect", () => {
      // console.log({ connected: socket.connected });
    });
    socket.on("connect_error", (e: any) => {
      // console.error(e);
    });

    setClient(socket);

    return () => {
      socket?.close();
      setClient(MockClient);
    };
  }, [token]);

  // avoid context churn
  const value = useMemo(() => ({ client }), [client]);

  return (
    <ReactContext.Provider value={value}>{children}</ReactContext.Provider>
  );
};
export function useServerEvent<N extends keyof ServerToClientEvents>(
  name: N,
  fn: (data: ServerToClientEvents[N]) => void
) {
  const { client } = useContext(ReactContext);
  useEffect(() => {
    client.on(name, fn);
    return () => {
      client.off(name, fn);
    };
  }, [client, fn, name]);
}

export default ServerEventsProvider;
