import {
  AudioMutedOutlined,
  AudioOutlined,
  LogoutOutlined,
  MessageOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Badge, Col, Row, Space, Spin } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "../Common/Buttons/IconButton";
import { Timer, useTimer } from "../Common/Timer";

interface Props {
  loading: boolean;
  audioOn: boolean;
  toggleAudio: () => void;
  videoOn: boolean;
  videoButtonDisabled: boolean;
  toggleVideo: () => void;
  chatCollapsed: boolean;
  noChat?: boolean;
  toggleChat: () => void;
  leaveCall: () => void;
  hasUnreadMessages: boolean;
  startTime: number;
  endTime: number;
  showOverlay: boolean;
  onOverlayChange: () => void;
}

const VideoOverlay: React.FC<Props> = ({
  loading,
  audioOn,
  toggleAudio,
  videoOn,
  videoButtonDisabled,
  toggleVideo,
  chatCollapsed,
  toggleChat,
  noChat,
  leaveCall,
  hasUnreadMessages,
  startTime,
  endTime,
  showOverlay,
  onOverlayChange,
}) => {
  const { t } = useTranslation("call");
  const { start: startTimer, value: timerValue } = useTimer();

  useEffect(() => {
    startTimer(Math.floor((Date.now() - startTime) / 1000));
  }, [startTime, startTimer]);
  return !loading ? (
    <Row
      className="absolute bottom-0 bg-white w-full p-4"
      align="middle"
      hidden={!showOverlay}
    >
      <Col span={2}>
        {endTime && (
          <Timer
            endTime={endTime}
            timerValue={timerValue}
            onUpdateTimer={onOverlayChange}
          />
        )}
      </Col>

      <Col span={noChat ? 6 : 8} offset={noChat ? 8 : 6}>
        <Space size="large">
          <IconButton
            icon={
              audioOn ? (
                <AudioOutlined style={Style.iconButton} />
              ) : (
                <AudioMutedOutlined style={Style.iconButton} />
              )
            }
            danger={!audioOn}
            type={audioOn ? "default" : "primary"}
            onClick={toggleAudio}
            label={`${t("videoOverlay.mic")}${" "}${
              audioOn ? t("videoOverlay.on") : t("videoOverlay.off")
            }`}
          />
          <IconButton
            shape="round"
            danger={!videoOn}
            icon={
              videoOn ? (
                <VideoCameraOutlined style={Style.iconButton} />
              ) : (
                <VideoCameraOutlined style={Style.iconButton} />
              )
            }
            disabled={videoButtonDisabled}
            size="large"
            type={videoOn ? "default" : "primary"}
            onClick={toggleVideo}
            label={
              videoButtonDisabled
                ? t("videoOverlay.disabled")
                : `${t("videoOverlay.video")} ${
                    videoOn ? t("videoOverlay.on") : t("videoOverlay.off")
                  }`
            }
          />
          {!noChat && (<IconButton
            shape="round"
            style={chatCollapsed ? Style.inactive : Style.active}
            icon={
              chatCollapsed ? (
                <Badge dot={hasUnreadMessages}>
                  <MessageOutlined style={Style.iconButton} />
                </Badge>
              ) : (
                <MessageOutlined style={Style.iconButton} />
              )
            }
            size="large"
            onClick={toggleChat}
            label={t("videoOverlay.chat")}
          />)}
          <IconButton
            size="large"
            onClick={leaveCall}
            label={t("videoOverlay.leaveCall")}
            danger
            icon={<LogoutOutlined className="text-lg" />}
            shape="circle"
          />
        </Space>
      </Col>
    </Row>
  ) : (
    <Row className="absolute bottom-0 bg-white w-full p-4 flex" align="middle">
      <Col className="m-auto">
        <Spin />
      </Col>
    </Row>
  );
};

const Style = {
  iconButton: {
    fontSize: 24,
  },
  active: {
    backgroundColor: "#f5f5f5",
  },
  inactive: {
    backgroundColor: "#fff",
  },
};
export default VideoOverlay;
