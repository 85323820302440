const PhoneFaceDown = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2279_4209)">
      <path
        d="M4.91043 15.2882L4.91232 19.3206C4.91232 19.813 5.10362 20.2809 5.45212 20.6294C5.80062 20.9779 6.26656 21.1711 6.7609 21.1692L11.102 21.173C11.5945 21.173 12.0642 20.9798 12.4127 20.6313C12.7631 20.2809 12.9544 19.8168 12.9544 19.3206L12.9563 15.9246C14.2437 15.4104 15.6176 15.1475 17.0038 15.1499C18.3979 15.1461 19.7616 15.4075 21.0533 15.9189L21.0514 19.3149C21.0514 19.8074 21.2427 20.2752 21.5912 20.6237C21.7625 20.7962 21.9664 20.9328 22.1912 21.0255C22.4159 21.1182 22.6569 21.1651 22.9 21.1635L27.243 21.1692C27.7355 21.1692 28.2052 20.976 28.5537 20.6275C28.9041 20.2771 29.0954 19.813 29.0954 19.3168L29.0954 15.2863C29.0954 14.4453 28.7563 13.6252 28.1616 13.0305C28.0385 12.9074 27.9097 12.7975 27.7677 12.6971C24.8471 10.6023 21.023 9.44887 17.0057 9.44698C12.9866 9.45077 9.16443 10.6061 6.23815 12.699C5.40667 13.2919 4.91232 14.2635 4.91043 15.2882ZM7.35563 14.2673C9.95803 12.4036 13.3862 11.377 17.0038 11.377C20.6215 11.377 24.0478 12.4017 26.6502 14.2654C26.9778 14.4984 27.171 14.881 27.1729 15.2882L27.1729 19.2467L22.9738 19.2486L22.9776 14.7067V14.6726L22.417 14.415C20.7205 13.6307 18.8737 13.2245 17.0046 13.2249C15.1355 13.2252 13.2888 13.632 11.5926 14.4169L11.032 14.6745L11.0338 19.2486L6.83477 19.2505L6.83477 15.292C6.83477 14.8829 7.02796 14.5003 7.35563 14.2673Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2279_4209">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(17 34) rotate(-135)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneFaceDown;
