import { PopupButton } from "@typeform/embed-react";
import { Button, ButtonProps } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { showToast } from "src/utils";

type Props = {
  formId: string;
  onSubmit: () => void;
} & ButtonProps;

const SurveyButton = ({ formId, onSubmit, ...props }: Props) => {
  const { t } = useTranslation(["feedback"]);

  return (
    <Button {...props}>
      <PopupButton
        id={formId}
        onSubmit={async () => {
          showToast(
            "feedback:survey.success",
            t("feedback:survey.success"),
            "success"
          );
          onSubmit();
        }}
      >
        {t("feedback:buttons.takeSurvey")}
      </PopupButton>
    </Button>
  );
};

export default SurveyButton;
