import React from "react";

interface Props {
  className?: string;
  bold?: boolean;
}

export const H1: React.FC<Props> = ({ className, children, bold }) => {
  return (
    <h1
      className={`${
        bold ? "font-bold" : ""
      } break-normal text-4xl md:text-3xl xl:text-5xl ${className}`}
    >
      {children}
    </h1>
  );
};

export const H2: React.FC<Props> = ({ className, bold, children }) => {
  return (
    <h2
      className={`${
        bold ? "font-semibold" : ""
      }  break-normal text-2xl xl:text-3xl  ${className}`}
    >
      {children}
    </h2>
  );
};

export const H3: React.FC<Props> = ({ className, children, bold }) => {
  return (
    <h3
      className={`${
        bold ? "font-semibold" : ""
      } text-xl break-normal	${className}`}
    >
      {children}
    </h3>
  );
};
