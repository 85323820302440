import { Col, Row, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import GoodbyeLottie from "src/assets/Lottie/Goodbye.json";
import PageLayout from "src/components/Common/PageLayout";
import { Quote } from "src/types/Common";
import { Call, Meeting } from "src/types/Meeting";
import { getLottieOptions } from "src/utils/UI";
import SurveyCard from "../Card/SurveyCard";
import { useIntakeForm } from "../ChicagoSurvey/useIntakeForm";
import ConnectionInfo, { Connection } from "../Inbox/ConnectionInfo";
import ConnectionsList from "./ConnectionsList";
import DashboardHeader from "./DashboardHeader";
import MeetingDetails from "./MeetingDetails";
import MeetingsList from "./MeetingsList";

export type IntakeFormStatus = "optional" | "required" | "completed";
interface Props {
  meetings: Meeting[];
  connections: Connection[];
  joinCall: (call: Call) => void;
  seeAllCalls: () => void;
  onBiographyClick: (quote: Quote) => void;
}
const Dashboard: React.FC<Props> = ({
  meetings,
  connections,
  joinCall,
  seeAllCalls,
  onBiographyClick,
}) => {
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting | null>(null);
  const [selectedConnection, setSelectedConnection] = useState<Connection>();
  const { t } = useTranslation(["dashboard"]);
  const { formId } = useIntakeForm();
  const showSurveyBanner = !!formId;

  return (
    <PageLayout>
      <Space direction="vertical" size="large" className="w-full p-6 pt-9">
        <Row>
          <Col span={24}>
            <DashboardHeader onBiographyClick={onBiographyClick} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <div className="flex flex-col gap-4">
              {showSurveyBanner && (
                <SurveyCard
                  title={t("dashboard:intakeForm.soft.title")}
                  body={t("dashboard:intakeForm.soft.body")}
                  formId={formId}
                  illustration={<Lottie {...getLottieOptions(GoodbyeLottie)} />}
                />
              )}
              <MeetingsList
                meetings={meetings}
                allMeetingsLength={meetings.length}
                selectMeeting={(meeting: Meeting) =>
                  setSelectedMeeting(meeting)
                }
                joinCall={joinCall}
                seeAllCalls={seeAllCalls}
              />
            </div>
          </Col>
          <Col span={8}>
            <ConnectionsList
              connections={connections}
              onSelect={setSelectedConnection}
            />
          </Col>
        </Row>
      </Space>
      <MeetingDetails
        selectedMeeting={selectedMeeting}
        onClose={() => setSelectedMeeting(null)}
      />
      {selectedConnection && (
        <ConnectionInfo
          connection={selectedConnection}
          open={!!selectedConnection}
          onClose={() => setSelectedConnection(undefined)}
        />
      )}
    </PageLayout>
  );
};

export default Dashboard;
