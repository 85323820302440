import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Layout } from "antd";
import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { client } from "src/api/apollo";
import ProtectedRoute, {
  ProtectedRouteProps,
} from "src/components/Hocs/ProtectedRoute";
import CallPage from "src/pages/Call";
import CallFeedbackPage from "src/pages/CallFeedback";
import DashboardPage from "src/pages/Dashboard";
import InboxPage from "src/pages/InboxPage";
import LoginPage from "src/pages/Login";
import MeetingsPage from "src/pages/Meetings";
import ProfilePage from "src/pages/Profile";
import SettingsPage from "src/pages/Settings";
import { useAppSelector } from "src/redux";
import "./App.less";
import { StreamDropType } from "./components/Call/Call";
import { CallExitType } from "./components/CallFeedback/CallFeedback";
import Modals from "./components/Modals/Modals";
import Sidebar from "./components/Sidebar";
import { useSessionStorage } from "./hooks/useSessionStorage";

type Props = {
  history: History;
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: "pathways-client@" + process.env.npm_package_version,
});

const App: React.FC<Props> = ({ history }) => {
  const { i18n } = useTranslation();
  const hasSideBar = useAppSelector((state) => state.ui.fullScreen);
  const session = useAppSelector((state) => state.session);
  const [token, setToken] = useSessionStorage("token", "");
  const [user, setUser] = useState(null);

  const logout = () => {
    sessionStorage.clear();
    setToken("");
    client.resetStore();
  };

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    authenticationPath: "/login",
    setUser,
    token,
    logout,
  };
  const showSideBar = token && !hasSideBar;

  useEffect(() => {
    i18n.changeLanguage(session.language);
  }, [session.language, i18n]);

  const ROUTES = [
    {
      path: "/meetings",
      component: MeetingsPage,
      label: "Meetings",
      key: "Meetings",
    },
    {
      path: "/settings",
      component: SettingsPage,
      label: "Settings",
      key: "Settings",
    },
    {
      path: "/profile/:id",
      component: ProfilePage,
      label: "Profile",
      key: "Profile",
    },
    { path: "/call/:id", component: CallPage, label: "Call", key: "Call" },
    {
      render: (
        props: RouteComponentProps<{
          id: string;
          exitType: CallExitType;
          droppedStreams: StreamDropType;
        }>
      ) => (
        <CallFeedbackPage
          {...props}
          logout={logout}
          id={props.match.params.id}
          exitType={props.match.params.exitType}
          droppedStreams={props.match.params.droppedStreams}
        />
      ),

      path: "/feedback/:id/:exitType/:droppedStreams",
      label: "Feedback",
      key: "Feedback",
    },
    { path: "/inbox", component: InboxPage, label: "Inbox", key: "Inbox" },
    { path: "/", component: DashboardPage, label: "Dash", key: "Dash" },
  ];

  return (
    <ConnectedRouter history={history}>
      <Modals />
      <Layout className="min-h-screen">
        {showSideBar && <Sidebar user={user} logout={logout} />}
        <Layout>
          <Switch>
            <Route
              exact
              path="/login/:state?"
              render={(props: RouteComponentProps) => (
                <LoginPage {...props} token={token} setToken={setToken} />
              )}
            />
            {ROUTES.map((route) => (
              <ProtectedRoute
                exact
                {...defaultProtectedRouteProps}
                path={route.path}
                component={route.component}
                render={route.render}
                key={route.label}
                token={token}
                setUser={setUser}
                logout={logout}
              ></ProtectedRoute>
            ))}
            <Redirect
              to={{
                pathname: "/login",
                state: { token, setToken },
              }}
            />
          </Switch>
        </Layout>
      </Layout>
    </ConnectedRouter>
  );
};

export default App;
