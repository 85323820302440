import { Collapse, Layout } from "antd";
import React from "react";
import { ConnectionStatus } from "src/api/graphql";
import { groupBy } from "src/utils/utils";
import ConnectionListItem, { TConnectionListItem } from "./ConnectionListItem";

interface Props {
  connections: TConnectionListItem[];
  selected?: TConnectionListItem;
  onSelect: (connection: TConnectionListItem) => void;
  className?: string;
}

const ConnectionList: React.FC<Props> = ({
  connections,
  selected,
  onSelect,
  className,
}) => {
  const groupedConnections = groupBy(
    connections,
    (conn) => conn.connectionStatus,
    [
      ConnectionStatus.Active,
      ConnectionStatus.Inactive,
      ConnectionStatus.Pending,
      ConnectionStatus.Rejected,
    ]
  );
  return (
    <Layout.Sider
      theme="light"
      width={300}
      className={`grid overflow-y-auto ${className || ""}`}
    >
      <Collapse
        defaultActiveKey={["1", "2"]}
        bordered={false}
        className="bg-white"
      >
        <Collapse.Panel
          header={
            <span className="pl-4 pt-4 pb-2 text-xs text-gray-400">ACTIVE</span>
          }
          key="1"
          collapsible="disabled"
          showArrow={false}
        >
          {groupedConnections[ConnectionStatus.Active].map((connection) => (
            <ConnectionListItem
              connection={connection}
              onSelectConnection={() => onSelect(connection)}
              selected={selected?.id === connection.id}
              key={connection.id}
              lastMessage={connection.lastMessage}
            />
          ))}
        </Collapse.Panel>
        {groupedConnections[ConnectionStatus.Inactive].length > 0 && (
          <Collapse.Panel
            style={{ borderBottom: "0px" }}
            header={
              <span className="pl-4 pt-4 pb-2 text-xs text-gray-400">
                DISABLED
              </span>
            }
            key="2"
          >
            {groupedConnections[ConnectionStatus.Inactive].map((connection) => (
              <ConnectionListItem
                connection={connection}
                onSelectConnection={() => onSelect(connection)}
                selected={selected?.id === connection.id}
                key={connection.id}
              />
            ))}
          </Collapse.Panel>
        )}
      </Collapse>
    </Layout.Sider>
  );
};

export default ConnectionList;
