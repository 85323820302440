import { CheckOutlined, DownOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MeetingType } from "src/api/graphql";
import PageLayout from "src/components/Common/PageLayout";
import useUser from "src/hooks/useUser";
import { Meeting } from "src/types/Meeting";
import { getFullName } from "src/utils/utils";
import MeetingDetails from "../Dashboard/MeetingDetails";
import Chip from "./Chip";
import MeetingsTable from "./MeetingsTable";

interface Props {
  meetings: Meeting[];
  loading: boolean;
}

const Meetings: React.FC<Props> = ({ meetings, loading }) => {
  const { t } = useTranslation("calls");

  const [meetingTypeFilter, setMeetingTypeFilter] =
    useState<MeetingType | null>(null);
  const [selectedVisitorsFilter, setSelectedVisitorsFilter] = useState<
    string[] | null
  >(null);
  const [selectedMeeting, setSelectedMeeting] = useState<null | Meeting>(null);

  const user = useUser();
  const eventTypesMenu = (
    <Menu>
      <Menu.Item>
        <Chip
          meetingTypeFilter={meetingTypeFilter}
          setMeetingTypeFilter={setMeetingTypeFilter}
          meetingType={MeetingType.VideoCall}
        />
      </Menu.Item>
      <Menu.Item>
        <Chip
          meetingTypeFilter={meetingTypeFilter}
          setMeetingTypeFilter={setMeetingTypeFilter}
          meetingType={MeetingType.InPersonVisit}
        />
      </Menu.Item>
    </Menu>
  );

  const visitorsMenu = () => {
    const names = meetings
      .flatMap((m) => m.visitors.map(getFullName))
      .filter((name, index, self) => self.indexOf(name) === index);
    return (
      <Menu>
        {names.map((name) => (
          <Menu.Item>
            <Row align="middle">
              <Button
                type="text"
                className="pl-0"
                onClick={() =>
                  selectedVisitorsFilter &&
                  !selectedVisitorsFilter.includes(name)
                    ? setSelectedVisitorsFilter([
                        ...selectedVisitorsFilter,
                        name,
                      ])
                    : setSelectedVisitorsFilter(
                        selectedVisitorsFilter &&
                          selectedVisitorsFilter.filter(
                            (selected) => selected !== name
                          )
                      )
                }
              >
                {name}
              </Button>
              {selectedVisitorsFilter &&
              selectedVisitorsFilter.includes(name) ? (
                <CheckOutlined />
              ) : null}
            </Row>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <PageLayout>
      <Space className="p-8 w-full" direction="vertical">
        <Row className="pb-2">
          <Col>
            <Tooltip title={t("tooltip")}>
              <Space>
                <Space direction="vertical" className="pr-10">
                  <Typography.Text type="secondary">
                    {"Weekly call limit"}
                  </Typography.Text>
                  <Space>
                    <Typography.Text strong className="text-base">
                      {user.callQuota}
                    </Typography.Text>
                  </Space>
                </Space>
                <Space direction="vertical">
                  <Typography.Text type="secondary">
                    {"Weekly visit limit"}
                  </Typography.Text>
                  <Space>
                    <Typography.Text strong className="text-base">
                      {user.visitQuota}
                    </Typography.Text>
                  </Space>
                </Space>
              </Space>
            </Tooltip>
          </Col>
        </Row>
        <Row align="middle">
          <Typography.Text type="secondary" className="pb-2">
            {"View: "}
          </Typography.Text>
          <Dropdown overlay={eventTypesMenu}>
            <Button type="link" className="ant-dropdown-link">
              All Event Types <DownOutlined />
            </Button>
          </Dropdown>
          <Dropdown overlay={visitorsMenu}>
            <Button type="link" className="ant-dropdown-link">
              All Visitors <DownOutlined />
            </Button>
          </Dropdown>
        </Row>
        <Row>
          <MeetingsTable
            meetings={meetings}
            meetingTypeFilter={meetingTypeFilter}
            selectedVisitorsFilter={selectedVisitorsFilter}
            setSelectedMeeting={setSelectedMeeting}
            loading={loading}
          />
        </Row>
      </Space>
      <MeetingDetails
        selectedMeeting={selectedMeeting}
        onClose={() => setSelectedMeeting(null)}
      />
    </PageLayout>
  );
};

export default Meetings;
