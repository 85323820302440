const BellOutlinedStrikethrough = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2210_5720)">
      <path
        d="M20.1429 18.8538H19.5V9.74665C19.5 5.96719 16.7063 2.84397 13.0714 2.32433V1.28237C13.0714 0.690402 12.592 0.210938 12 0.210938C11.408 0.210938 10.9286 0.690402 10.9286 1.28237V2.32433C7.29375 2.84397 4.5 5.96719 4.5 9.74665V18.8538H3.85714C3.38304 18.8538 3 19.2368 3 19.7109V20.5681C3 20.6859 3.09643 20.7824 3.21429 20.7824H9C9 22.4377 10.3446 23.7824 12 23.7824C13.6554 23.7824 15 22.4377 15 20.7824H20.7857C20.9036 20.7824 21 20.6859 21 20.5681V19.7109C21 19.2368 20.617 18.8538 20.1429 18.8538ZM12 22.0681C11.2902 22.0681 10.7143 21.4922 10.7143 20.7824H13.2857C13.2857 21.4922 12.7098 22.0681 12 22.0681ZM6.42857 18.8538V9.74665C6.42857 8.25737 7.00714 6.85915 8.05982 5.80647C9.1125 4.7538 10.5107 4.17522 12 4.17522C13.4893 4.17522 14.8875 4.7538 15.9402 5.80647C16.9929 6.85915 17.5714 8.25737 17.5714 9.74665V18.8538H6.42857Z"
        fill="#2E47BD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.1257 2.8482L1.38394 23.59L-0.0302734 22.1758L20.7115 1.43398L22.1257 2.8482Z"
        fill="#2E47BD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.3611 4.07964L2.61929 24.8214L1.20508 23.4072L21.9469 2.66543L23.3611 4.07964Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2210_5720">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default BellOutlinedStrikethrough;
