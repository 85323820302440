import React from "react";
import ProfileInfo from "./ProfileInfo";

interface Props {
  openProfileImageModal: () => void;
}

const Profile: React.FC<Props> = ({ openProfileImageModal }) => {
  return <ProfileInfo onEdit={openProfileImageModal} />;
};

export default Profile;
