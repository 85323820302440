import { TeamOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { MeetingStatus, MeetingType } from "src/api/graphql";
import {
  formatDate,
  getVisitLabel,
  millisecondsToHoursOrMinutesLabel,
} from "src/utils";

interface Props {
  meeting: {
    interval: { startAt: number; seconds: number | null };
    call: {
      duration: number | null;
    } | null;
    meetingType: MeetingType;
    status: MeetingStatus;
  };
  className?: string;
}

const MeetingCard = ({ meeting, className }: Props) => {
  return (
    <div className={className}>
      <div className="grid gap-2 bg-gray-100 px-2 rounded py-1 border border-gray-200">
        <div className="flex gap-2 items-center ">
          {meeting.meetingType === MeetingType.VideoCall ? (
            <VideoCameraOutlined className={"text-gray-500 text-xs"} />
          ) : (
            // In-person
            <TeamOutlined className={"text-gray-500 text-xs"} />
          )}
          <span className="text-gray-500 text-sm">
            {`${getVisitLabel(meeting.meetingType)} •
            ${
              !!meeting.interval.seconds
                ? millisecondsToHoursOrMinutesLabel(
                    meeting.interval.seconds * 1000
                  )
                : ""
            }`}
          </span>
          <span className="pl-3 text-gray-500 text-xs">
            {formatDate(new Date(meeting.interval.startAt), "monthdaytime")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MeetingCard;
