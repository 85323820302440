import { gql } from "@apollo/client";
import { useState } from "react";
import { client } from "src/api/apollo";
import { AuthInfo } from "src/types/Auth";

const query = gql`
  query WriteAuthInfo {
    authInfo {
      id
      token
      type
    }
  }
`;

export const useAuthInfo = () => {
  const [authInfo, setValue] = useState<AuthInfo>(() => {
    const data = client.readQuery({ query });
    return data?.authInfo || null;
  });

  const setAuthInfo = (value: AuthInfo) => {
    client.writeQuery({
      query,
      data: {
        authInfo: {
          __typename: "AuthInfo",
          ...value,
        },
      },
    });
    setValue(value);
  };

  return { authInfo, setAuthInfo };
};
