import { format } from "date-fns";

export const MAX_VOICE_CALL_DURATION_PERIOD_MINUTES = 60;

export function formatDate(
  date: Date,
  type:
    | "date"
    | "time"
    | "timea"
    | "datetime"
    | "datetimea"
    | "dateyear"
    | "monthdaytime"
    | "monthdayyear"
    | "weekdaymonthdaytimea"
): string {
  if (type === "date") return format(date, "MM/dd/yy");
  if (type === "time") return format(date, "HH:mm");
  if (type === "timea") return format(date, "hh:mm a");
  if (type === "datetime") return format(date, "MM/dd/yy HH:mm");
  if (type === "datetimea") return format(date, "MM/dd/yy hh:mm a");
  if (type === "dateyear") return format(date, "yyyy-MM-dd");
  if (type === "monthdaytime") return format(date, "MMM dd, hh:mm a");
  if (type === "monthdayyear") return format(date, "MMM dd, yyyy");
  if (type === "weekdaymonthdaytimea")
    return format(date, "EEEE MMM dd, hh:mm a");
  return "";
}

// useful util to create a duration label for the Meeting's Interval
export function intervalSecondsToMinutesLabel(seconds?: number | null): string {
  const mins = seconds && Math.round(seconds / 60);
  return mins ? `${mins} ${mins === 1 ? "min" : "mins"}` : "";
}

// Like the above, but round hour amounts are rendered as such.
export function millisecondsToHoursOrMinutesLabel(
  milliseconds?: number | null
): string {
  const mins = milliseconds && Math.round(milliseconds / (60 * 1000));
  const hours = mins && mins % 60 === 0 ? mins / 60 : null;
  if (hours) return `${hours} ${hours === 1 ? "hour" : "hrs"}`;
  return `${mins} ${mins === 1 ? "min" : "mins"}`;
}

// 00:00:00 format
export function millisecondsToHoursMinutesSecondsDigitsLabel(
  milliseconds: number | undefined
): string {
  if (!milliseconds) return "00:00:00";
  const hours = Math.floor(milliseconds / (60 * 60 * 1000));
  const remainder1 = milliseconds - hours * 60 * 60 * 1000;
  const minutes = Math.floor(remainder1 / (60 * 1000));
  const remainder2 = remainder1 - minutes * 60 * 1000;
  const seconds = Math.floor(remainder2 / 1000);
  `${Math.floor(remainder2 / 1000)}`.padStart(2, "0");
  const hoursDigits = `${hours}`.padStart(2, "0");
  const minutesDigits = `${minutes}`.padStart(2, "0");
  const secondsDigits = `${seconds}`.padStart(2, "0");
  return `${hoursDigits}:${minutesDigits}:${secondsDigits}`;
}

export function millisecondsToHoursMinutesSecondsTextLabel(
  milliseconds?: number | null
): string {
  if (!milliseconds) return "0 seconds";
  const hours = Math.floor(milliseconds / (60 * 60 * 1000));
  const minutesRemaining = Math.floor(
    (milliseconds - hours * 60 * 60 * 1000) / (60 * 1000)
  );
  const secondsRemaining = Math.floor(
    (milliseconds - hours * 60 * 60 * 1000 - minutesRemaining * 60 * 1000) /
      1000
  );
  if (hours === 0 && minutesRemaining === 0) {
    if (secondsRemaining === 1) return "1 second";
    else return `${secondsRemaining} seconds`;
  }
  if (hours === 0)
    return `${minutesRemaining}${
      secondsRemaining > 0 ? ":" + `${secondsRemaining}`.padStart(2, "0") : ""
    } ${minutesRemaining === 1 ? "min" : "mins"}`;
  const hoursFormatted =
    hours > 0 ? (hours === 1 ? "1 hr" : `${hours} hrs`) : "";
  const minutesFormatted =
    minutesRemaining > 0
      ? minutesRemaining === 1
        ? "1 min"
        : `${minutesRemaining} mins`
      : "";
  const secondsFormatted =
    secondsRemaining > 0
      ? secondsRemaining === 1
        ? "1 sec"
        : `${secondsRemaining} secs`
      : "";
  return `${hoursFormatted}${
    hoursFormatted && minutesFormatted ? " " : ""
  }${minutesFormatted}${
    (hoursFormatted || minutesFormatted) && secondsFormatted ? " " : ""
  }${secondsFormatted}`;
}
