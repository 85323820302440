import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Col, Row, Select, Space, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import { format } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ParticipantComment } from "src/api/graphql";
import { CancelMeetingDocument } from "src/graphql/mutations/CancelMeeting.generated";
import { CancelMeetingModalData } from "src/types/UI";
import {
  getFirstNames,
  getFullNames,
  intervalSecondsToMinutesLabel,
  showToast,
} from "src/utils";

interface Props {
  data: CancelMeetingModalData;
  onClose: () => void;
}

const CancelMeetingModal: React.FC<Props> = ({ data, onClose: closeModal }) => {
  const { t } = useTranslation("modals");
  const [reason, setReason] = useState<ParticipantComment>();
  const meeting = data.entity;
  const fullName = getFullNames(meeting.visitors);
  const startDate = format(new Date(meeting.interval.startAt), "EEEE, MMMM d");
  const startTime = format(new Date(meeting.interval.startAt), "h:mm aaa OOO");

  const firstName = getFirstNames(meeting.visitors);
  const CANCEL_REASONS = [
    {
      key: ParticipantComment.CantMakeOnce,
      value: t("cancelMeetingModal.cant_make_once"),
    },
    {
      key: ParticipantComment.CantMakeThisTimeThisWeekday,
      value: t("cancelMeetingModal.cant_make_this_time_this_weekday", {
        dayOfWeek: format(new Date(meeting.interval.startAt), "EEEE"),
      }),
    },
    {
      key: ParticipantComment.CantMakeThisTimeWeekdays,
      value: t("cancelMeetingModal.cant_make_this_time_weekdays"),
    },
    {
      key: ParticipantComment.CantMakeThisTimeAnyDay,
      value: t("cancelMeetingModal.cant_make_this_time_any_day"),
    },
  ];

  const [cancelMeeting] = useMutation(CancelMeetingDocument, {
    onCompleted: () => {
      showToast("cancelCall", t("cancelMeetingModal.toastSuccess"), "success");
    },
    onError: (e) => {
      Sentry.captureException(e);
      showToast("cancelCall", t("cancelMeetingModal.toastFailure"), "error");
    },
  });

  return (
    <Modal
      title={t("cancelMeetingModal.title")}
      visible={true}
      okText={t("cancelMeetingModal.okText")}
      onOk={async () => {
        await cancelMeeting({
          variables: {
            input: {
              meetingId: meeting.id,
              participantComment: reason,
            },
          },
        });
        closeModal();
      }}
      onCancel={closeModal}
      className="rounded-sm"
    >
      <Space direction="vertical" size="large">
        <Row>
          <Typography.Text>
            {t("cancelMeetingModal.messageOne", { connectionName: fullName })}
          </Typography.Text>
        </Row>
        <Row>
          <Col>
            <div>
              <Typography.Text>{startDate}</Typography.Text>
            </div>
            <div>
              <Typography.Text>
                {startTime} •{" "}
                {intervalSecondsToMinutesLabel(meeting.interval.seconds)}
              </Typography.Text>
            </div>
          </Col>
        </Row>

        <Row>
          <Typography.Text>
            {t("cancelMeetingModal.messageTwo", {
              connectionFirstName: firstName,
            })}
          </Typography.Text>
        </Row>
      </Space>

      <Select
        className="w-full"
        placeholder={t("cancelMeetingModal.dropdownPlaceholder")}
        onChange={(value: ParticipantComment) => setReason(value)}
      >
        {CANCEL_REASONS.map((reason) => (
          <Select.Option key={reason!.key} value={reason!.key}>
            {reason?.value}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default CancelMeetingModal;
