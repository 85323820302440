import { useEffect, useRef, useState } from "react";

interface Change<T> {
  previous: T | null;
  current: T;
}

export default function useChangeValue<T>(value: T) {
  const previousRef = useRef<T | null>(value);
  const [returnValue, setReturnValue] = useState<Change<T>>({
    previous: null,
    current: value,
  });

  useEffect(() => {
    setReturnValue({
      previous: previousRef.current,
      current: value,
    });
    previousRef.current = value;
  }, [value]);

  return returnValue;
}
