import { Radio, Rate } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { isNegativeCallRating } from "src/utils";

type Props = {
  rating?: number;
  onChangeRating: (rating: number) => void;
  feedback?: string;
  onChangeFeedback: (feedback: string) => void;
  feedbackOptions: string[];
};

const CallRate = ({
  rating,
  feedback,
  onChangeFeedback,
  onChangeRating,
  feedbackOptions,
}: Props) => {
  const { t } = useTranslation(["feedback", "common"]);

  const DESC = [
    t("feedback:ratings.terrible"),
    t("feedback:ratings.bad"),
    t("feedback:ratings.normal"),
    t("feedback:ratings.good"),
    t("feedback:ratings.wonderful"),
  ];

  const isNegativeRating = isNegativeCallRating(rating);

  return (
    <div className="flex flex-col gap-2 justify-center items-center">
      <span className="font-medium mb-4 text-center">
        {isNegativeRating
          ? t("feedback:qualityRating.review.reviewTitle")
          : t("feedback:qualityRating.question")}
      </span>
      <span
        className={`${
          rating ? "" : "hidden"
        } text-blue-500 text-center text-lg`}
      >
        {rating ? DESC[rating - 1] : "invisible"}
      </span>
      <Rate
        value={rating}
        onChange={onChangeRating}
        className="text-blue-500 text-4xl"
      />
      {isNegativeRating ? (
        <div className="mt-4">
          <Radio.Group
            onChange={(e) => onChangeFeedback(e.target.value)}
            value={feedback}
            className="grid"
          >
            {feedbackOptions.map((opt) => (
              <Radio value={opt}>{opt}</Radio>
            ))}
          </Radio.Group>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default CallRate;
