import { PhoneOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button, Layout, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FacilityFeature, FacilityService } from "src/api/graphql";
import { GetFacilityPublicIdDocument } from "src/graphql/queries/GetFacilityPublicId.generated";
import useFacilityFeature from "src/hooks/useFacilityFeature";
import { formatDate, getFullName } from "src/utils";
import VisitorAvatar from "../Avatar/VisitorAvatar";
import ConnectionInfo from "./ConnectionInfo";
import { TConnectionListItem } from "./ConnectionListSidebar/ConnectionListItem";
import StartVoiceCallModal from "./VoiceCall/StartVoiceCallModal";

interface Props {
  connection: TConnectionListItem;
  onCall: () => Promise<void>;
  callIsLoading?: boolean;
}

const InboxHeader = ({ connection, onCall, callIsLoading }: Props) => {
  const [viewInfo, setViewInfo] = useState(false);
  const [isStartVoiceCallModalOpen, setIsStartVoiceCallModalOpen] =
    useState(false);
  const facilityHasVoiceCall = useFacilityFeature(FacilityFeature.VoiceCall);
  const { t } = useTranslation(["common"]);
  const { data } = useQuery(GetFacilityPublicIdDocument);
  const voiceCallRestriction = connection.activeRestrictions.find(
    (r) => r.service === FacilityService.VoiceCall && r.isActive
  );

  useEffect(() => {
    if (!callIsLoading) setIsStartVoiceCallModalOpen(false);
  }, [callIsLoading]);
  const CallButton = () => (
    <Button
      type="primary"
      icon={<PhoneOutlined className="align-baseline" />}
      loading={callIsLoading}
      disabled={callIsLoading || !!voiceCallRestriction}
      onClick={() => {
        setIsStartVoiceCallModalOpen(true);
      }}
    >
      {t("common:verbs.call")}
    </Button>
  );

  return (
    <>
      <Layout.Header className="bg-white flex items-center justify-between px-4 border-gray-100 border-b">
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => {
            setViewInfo(true);
          }}
        >
          <VisitorAvatar visitor={connection.visitor} size="large" />
          <span className="font-medium text-lg">
            {getFullName(connection.visitor)}
          </span>
        </div>
        <div className="flex gap-2 items-center">
          {facilityHasVoiceCall &&
            (voiceCallRestriction ? (
              <Tooltip
                title={`${
                  data?.currentInmate?.facility.publicId
                } has disabled calls with ${connection.visitor.firstName} ${
                  !!voiceCallRestriction.expiresAt
                    ? "until " +
                      formatDate(
                        new Date(voiceCallRestriction.expiresAt),
                        "datetimea"
                      )
                    : "indefinitely"
                }`}
              >
                {
                  " " /* Oh right, these tooltips don't render without an invisible space, grumble. */
                }
                <CallButton />
              </Tooltip>
            ) : (
              <CallButton />
            ))}
          {facilityHasVoiceCall && (
            <StartVoiceCallModal
              onConfirm={onCall}
              onCancel={() => {
                setIsStartVoiceCallModalOpen(false);
              }}
              isOpen={isStartVoiceCallModalOpen}
              callIsLoading={!!callIsLoading}
            />
          )}
        </div>
      </Layout.Header>
      <ConnectionInfo
        connection={connection}
        open={viewInfo}
        onClose={() => setViewInfo(false)}
      />
    </>
  );
};

export default InboxHeader;
