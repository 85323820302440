import { PhoneOutlined } from "@ant-design/icons";
import { MeetingStatus, MeetingType } from "src/api/graphql";
import {
  formatDate,
  getVisitLabel,
  millisecondsToHoursMinutesSecondsTextLabel,
} from "src/utils";

interface Props {
  meeting: {
    interval: { startAt: number; seconds: number | null };
    call: {
      duration: number | null;
    } | null;
    meetingType: MeetingType;
    status: MeetingStatus;
  };
  className?: string;
}

const VoiceCallCard = ({ meeting, className }: Props) => {
  return (
    <div className={className}>
      <div className="grid gap-2 bg-gray-100 px-2 rounded py-1 border border-gray-200">
        <div className="flex gap-2 items-center ">
          {meeting.status === MeetingStatus.NoShow ? (
            <PhoneOutlined className={"text-red-500 text-xs"} />
          ) : (
            <PhoneOutlined className={"text-gray-500 text-xs"} />
          )}
          <span className="text-gray-500 text-sm">
            {`${
              meeting.status === MeetingStatus.NoShow ? "Missed " : ""
            } ${getVisitLabel(meeting.meetingType)}
            ${
              meeting.status === MeetingStatus.Ended ||
              meeting.status === MeetingStatus.Terminated
                ? " • " +
                  millisecondsToHoursMinutesSecondsTextLabel(
                    meeting.call?.duration
                  )
                : ""
            }`}
          </span>
          <span className="pl-3 text-gray-500 text-xs">
            {formatDate(new Date(meeting.interval.startAt), "monthdaytime")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default VoiceCallCard;
