import { Typography, Row } from "antd";
import React from "react";
import { VisitorFragmentFragment } from "src/graphql/fragments/VisitorFragment.generated";
import { getFullName } from "src/utils";
import VisitorAvatar from "src/components/Avatar/VisitorAvatar";
import { ConnectionFragmentFragment } from "src/graphql/fragments/ConnectionFragment.generated";
import { ConnectionRestrictionFragmentFragment } from "src/graphql/fragments/ConnectionRestrictionFragment.generated";
import { MessageFragmentFragment } from "src/graphql/fragments/MessageFragment.generated";
import { format, isToday, isYesterday } from "date-fns";

export type TConnectionListItem = ConnectionFragmentFragment & {
  visitor: VisitorFragmentFragment;
  messages: {
    unreadMessageCount: number;
  };
  lastMessage: MessageFragmentFragment | null;
  activeRestrictions: ConnectionRestrictionFragmentFragment[];
};

interface Props {
  connection: TConnectionListItem;
  lastMessage?: MessageFragmentFragment | null;
  selected?: boolean;
  onSelectConnection: () => void;
}

const getDate = (message: MessageFragmentFragment) => {
  const sentAt = new Date(message.createdAt);
  if (isYesterday(sentAt)) return "Yesterday";
  if (isToday(sentAt)) return "Today";
  return format(sentAt, "MM/dd");
};

const ConnectionListItem: React.FC<Props> = ({
  connection,
  lastMessage,
  selected,
  onSelectConnection,
}) => {
  return (
    <Row
      className={`border-t ${selected ? "bg-blue-50" : ""} `}
      key={connection.id}
      onClick={onSelectConnection}
    >
      <div className="flex gap-2 p-4 cursor-pointer justify-start">
        <div className="shrink-0">
          <VisitorAvatar visitor={connection.visitor} />
        </div>
        <div className="grid">
          <div className="flex mb-2">
            <Typography.Text className="truncate font-medium">
              {getFullName(connection.visitor)}
            </Typography.Text>
            {connection.messages.unreadMessageCount > 0 && (
              <Typography.Text className="ant-typography text-sm text-blue-500 pl-2">
                {` ${connection.messages.unreadMessageCount} new`}
              </Typography.Text>
            )}
          </div>
          <div className="truncate flex flex-row no-wrap">
            <Typography.Text type="secondary" className="truncate">
              {lastMessage
                ? `${
                    lastMessage.sender.__typename === "Inmate"
                      ? "You"
                      : lastMessage.sender.firstName
                  }: ${lastMessage.content}`
                : "No messages"}
            </Typography.Text>
            {lastMessage && (
              <Typography.Text type="secondary">
                {`• ${getDate(lastMessage)}`}
              </Typography.Text>
            )}
          </div>
        </div>
      </div>
    </Row>
  );
};

export default ConnectionListItem;
