import { Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  visible?: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export default function PrivacyAcknowledgementModal({
  visible,
  onConfirm,
  onClose,
}: Props): ReactElement {
  const { t } = useTranslation(["modals", "common"]);

  return (
    <Modal
      title={t("modals:kioskConfirmationModal.privacyHeader")}
      visible={visible}
      onOk={onConfirm}
      onCancel={onClose}
      className="rounded-sm flex flex-col align-center"
      okText={t("common:confirm")}
      cancelText={t("common:logout")}
    >
      <div className="overflow-y-auto max-h-64">
        <Typography.Text>
          {t("modals:kioskConfirmationModal.privacyBody")}
        </Typography.Text>
      </div>
    </Modal>
  );
}
