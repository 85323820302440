import { Card as AntdCard, CardProps } from "antd";
import React from "react";

interface Props extends CardProps {}

const Card = ({ className, ...props }: Props) => {
  return <AntdCard {...props} className={`rounded ${className || ""}`} />;
};

export default Card;
