import { Divider, Input } from "antd";
import { Message } from "connect-call-client";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MessageDisplay from "./MessageDisplay";

interface Props {
  messages: (Message & { status: "sent" | "errored" })[];
  onSend: (message: string) => void;
  visitorFullName: string;
}

const Chat: React.FC<Props> = ({ messages, onSend , visitorFullName }) => {
  const { t } = useTranslation("call");

  const [draftMessage, setDraftMessage] = useState("");
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const onSendMessage = async () => {
    const cleanMessage = draftMessage.trim();
    if (!cleanMessage.length) return;
    onSend(cleanMessage);
    setDraftMessage("");
  };

  useEffect(() => {
    if (!messagesContainerRef.current) return;
    messagesContainerRef.current.scroll({
      top: messagesContainerRef.current.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }, [messagesContainerRef, messages]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col overflow-y-auto" ref={messagesContainerRef}>
        {messages.map((message, idx) => (
          <MessageDisplay key={idx} message={message} className="mt-4" visitorFullName={visitorFullName}/>
        ))}
      </div>
      <div
        className="mt-auto mb-8 flex-shrink-0 h-1/4"
        style={{ minHeight: "25%" }}
      >
        <Divider />
        <Input.TextArea
          value={draftMessage}
          rows={2}
          onChange={(e) => setDraftMessage(e.target.value)}
          onPressEnter={onSendMessage}
          onSubmit={onSendMessage}
          placeholder={t("chat.placeholder")}
          autoFocus
          bordered={false}
        />
      </div>
    </div>
  );
};

export default Chat;
