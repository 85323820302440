import { Card, Row } from "antd";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectionStatus } from "src/api/graphql";
import { Connection } from "../Inbox/ConnectionInfo";
import ConnectionItem from "./ConnectionItem";
interface Props {
  connections: Connection[];
  onSelect: (connection: Connection) => void;
}

const ConnectionsList: React.FC<Props> = ({ connections, onSelect }: Props) => {
  const { t } = useTranslation("dashboard");
  const [activeTab, setActiveTab] = useState(ConnectionStatus.Active);
  const tabList = [
    {
      key: ConnectionStatus.Active,
      tab: t("connection.active"),
    },
    {
      key: ConnectionStatus.Pending,
      tab: t("connection.pending"),
    },
  ];

  const filteredConnections = useMemo(
    () => connections.filter((c) => c.connectionStatus === activeTab),
    [activeTab, connections]
  );

  return (
    <Card
      title={t("connection.title")}
      tabList={tabList}
      activeTabKey={activeTab}
      onTabChange={(key) => setActiveTab(key as ConnectionStatus)}
    >
      <Row justify="space-around">
        {filteredConnections.map((c) => (
          <div key={c.visitor.id} onClick={() => onSelect(c)}>
            <ConnectionItem visitor={c.visitor} />
          </div>
        ))}
      </Row>
    </Card>
  );
};

export default ConnectionsList;
