import { Descriptions, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { FacilityFeature, FacilityService } from "src/api/graphql";
import useFacilityFeature from "src/hooks/useFacilityFeature";
import { getFullName, toTitleCase } from "src/utils";
import { TConnectionListItem } from "./ConnectionListSidebar/ConnectionListItem";

export type Connection = Pick<
  TConnectionListItem,
  "visitor" | "restrictions" | "relationship" | "connectionStatus"
>;
interface Props {
  connection: Connection;
  open: boolean;
  onClose: () => void;
}
const ServiceNames: Record<FacilityService, string> = {
  [FacilityService.VideoCall]: "Video calls",
  [FacilityService.InPersonVisit]: "In-person visits",
  [FacilityService.Emessaging]: "Messaging",
  [FacilityService.VoiceCall]: "Voice Call",
  [FacilityService.Webinar]: "Webinar",
};

function useFacilityServices(): FacilityService[] {
  const hasVoiceCalls = useFacilityFeature(FacilityFeature.VoiceCall);
  const hasVideoCalls = useFacilityFeature(FacilityFeature.VideoCall);
  const hasEmessaging = useFacilityFeature(FacilityFeature.Emessaging);

  return [
    FacilityService.InPersonVisit,
    hasVideoCalls && FacilityService.VideoCall,
    hasVoiceCalls && FacilityService.VoiceCall,
    hasEmessaging && FacilityService.Emessaging,
  ].filter((i): i is FacilityService => !!i);
}

const ConnectionInfo = ({ connection, open, onClose }: Props) => {
  const { t } = useTranslation("common");
  const services = useFacilityServices();

  const labelStyle = { color: "#aaaaaa" };
  return (
    <Drawer visible={open} title="Profile Info" onClose={onClose} width={350}>
      <div className="flex flex-col">
        <Descriptions column={1}>
          <Descriptions.Item label="Name" labelStyle={labelStyle}>
            {getFullName(connection.visitor)}
          </Descriptions.Item>
          <Descriptions.Item label="Relationship" labelStyle={labelStyle}>
            {toTitleCase(`${connection.relationship}`)}
          </Descriptions.Item>
          <Descriptions.Item label="Status" labelStyle={labelStyle}>
            {toTitleCase(`${connection.connectionStatus}`)}
          </Descriptions.Item>
          <Descriptions.Item label="Email" labelStyle={labelStyle}>
            {connection.visitor.email}
          </Descriptions.Item>
          {services.map((service) => (
            <Descriptions.Item
              label={ServiceNames[service]}
              labelStyle={labelStyle}
            >
              {connection.restrictions.some(
                (restriction) =>
                  restriction.service === service && restriction.isActive
              )
                ? t("notAllowed")
                : t("allowed")}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </div>
    </Drawer>
  );
};

export default ConnectionInfo;
