import { AudioMutedOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Layout, Typography } from "antd";
import { Message, useConnectCall } from "connect-call-client";
import { differenceInSeconds } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import JoinCallSound from "src/assets/Sounds/EnterCall.wav";
import LeaveCallSound from "src/assets/Sounds/LeaveCall.wav";
import MessageReceivedSound from "src/assets/Sounds/MessageReceived.mp3";
import VideoMePlaceholder from "src/components/Call/VideoMePlaceholder";
import VideoOverlay from "src/components/Call/VideoOverlay";
import Chat from "src/components/Chat";
import { FADING_ANIMATION_DURATION } from "src/constants";
import { GetCallQuery } from "src/graphql/queries/GetCall.generated";
import { useAuthInfo } from "src/hooks/useAuthInfo";
import useChange from "src/hooks/useChange";
import useUser from "src/hooks/useUser";
import "src/i18n/config";
import { FAQResource } from "src/types/UI";
import { getFirstNames } from "src/utils";
import { WRAPPER_PADDING } from "src/utils/constants";
import {
  getFullName,
  getInitials,
  openNotificationWithIcon,
  showToast,
} from "src/utils/utils";
import useSound from "use-sound";
import AvatarGroup from "../Avatar/AvatarGroup";
import { CallExitType } from "../CallFeedback/CallFeedback";
import Loader from "../Loader";
import Video from "./Video";
import { WaitingRoomCard } from "./WaitingRoomCard";

declare global {
  interface Window {
    Debug: any;
  }
}

type Call = GetCallQuery["meeting"];

interface Props {
  call: Call;
  onLeave: (exitType: CallExitType, droppedStreams: StreamDropType) => void;
  push: (path: string) => void;
  openInfoModal: (resource: FAQResource) => void;
  openTestConnectionModal: () => void;
}

/**
 * The tracking of dropped streams uses bitwise operations.  https://www.w3schools.com/js/js_bitwise.asp
 * We leverage the effect of redundant bitwise ORs (|) to track all 4 states,
 * even though we never explicity set "localAndPeer".  This eliminates the need for
 * conditional checks on each update, and provides explicit values for each state.
 * Setting local and peer repeatedly throughough a session always produces "localAndPeer".
 * e.g.: 0x00 | 0x01 | 0x02 | 0x01 | 0x01 | 0x02 | 0x02 | 0x02 == 0x03
 * usage:  setDroppedStreams(droppedStreams | DroppedStreams["local" ...or "peer"]);
 */
const streamDropTypes = ["none", "local", "peer", "localAndPeer"] as const;
export type StreamDropType = typeof streamDropTypes[number];
const DroppedStreams: Record<StreamDropType, number> = {
  [streamDropTypes[0]]: 0x00,
  [streamDropTypes[1]]: 0x01,
  [streamDropTypes[2]]: 0x02,
  [streamDropTypes[3]]: 0x03,
};
const getDropType = (value: number): StreamDropType => {
  return streamDropTypes.find((key) => DroppedStreams[key] === value) || "none";
};

const CallScreen: React.FC<Props> = React.memo(
  ({ call, onLeave, push, openInfoModal, openTestConnectionModal }) => {
    const { t } = useTranslation(["call", "common"]);

    const [showOverlay, setShowOverlay] = useState(true);
    const [chatCollapsed, setChatCollapsed] = useState(true);
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
    const [peerEverConnected, setPeerEverConnected] = useState(false);
    const [erroredMessages, setErroredMessages] = useState<Message[]>([]);
    const { authInfo } = useAuthInfo();
    const [playJoinCall] = useSound(JoinCallSound);
    const [playLeaveCall] = useSound(LeaveCallSound);
    const [playMessageReceived] = useSound(MessageReceivedSound);
    const user = useUser();
    const [deferredPoorQualityToast, setDeferredPoorQualityToast] =
      useState<() => void>();
    const [droppedStreams, setDroppedStreams] = useState<number>(
      DroppedStreams["none"]
    );

    const handleNewMessage = useCallback(
      ({ user, contents }: Message) => {
        setHasUnreadMessages(true);
        if (chatCollapsed) playMessageReceived();
        if (user.role === "monitor") {
          openNotificationWithIcon(t("call:doc.warning"), contents, "warning");
        }
      },
      [playMessageReceived, t, chatCollapsed]
    );

    const handlePeerConnected = useCallback(() => {
      setPeerEverConnected(true);
      playJoinCall();
      showToast(
        "participantConnect",
        `${getFirstNames(call.visitors)} ${t("call:peer.joinedCallTitle")}.`,
        "info"
      );
    }, [playJoinCall, t, call.visitors, setPeerEverConnected]);

    const handlePeerDisconnected = useCallback(() => {
      playLeaveCall();
      showToast(
        "participantDisconnect",
        `${call.visitors[0].firstName} ${t(
          "call:peer.participantDisconnect"
        )}.`,
        "info"
      );
    }, [playLeaveCall, t, call.visitors]);

    // type narrowing
    if (!call.call?.url || !call.call.token)
      throw new Error("Invalid call data");

    const {
      status,
      error,
      localAudio,
      localVideo,
      connectionState,
      toggleAudio,
      toggleVideo,
      produceTrack,
      peers,
      messages,
      sendMessage,
    } = useConnectCall({
      call: {
        id: call.id,
        url: call.call.url!,
        token: call.call.token!,
      },
      user: authInfo,
      onPeerConnected: handlePeerConnected,
      onPeerDisconnected: handlePeerDisconnected,
      onNewMessage: handleNewMessage,
    });
    if (error) throw error;
    const peer = peers[0];

    // handle call status changes
    useEffect(() => {
      switch (status) {
        case "connected":
          navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => produceTrack(stream.getAudioTracks()[0]));
          navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => produceTrack(stream.getVideoTracks()[0]));
          break;
        case "missing_monitor":
          showToast(
            "callStatus",
            t("call:callStatus.missingMonitor"),
            "loading"
          );
          break;
        case "live":
          showToast("callStatus", t("call:callStatus.live"), "info");
          break;
        case "terminated":
          onLeave("terminated", getDropType(droppedStreams));
          showToast("callStatus", t("call:callStatus.terminated"), "info");
          break;
        case "ended":
          onLeave("ended", getDropType(droppedStreams));
          showToast("callStatus", t("call:callStatus.ended"), "info");
          break;
        default:
          break;
      }
    }, [onLeave, droppedStreams, t, status, produceTrack]);

    // mark messages as read
    useEffect(() => {
      if (!chatCollapsed) setHasUnreadMessages(false);
    }, [chatCollapsed, setHasUnreadMessages]);

    // alert when remote video pauses or resumes
    const remoteVideoTracks = peer?.stream.getVideoTracks().length;
    useEffect(() => {
      if (remoteVideoTracks === undefined) return;
      showToast(
        "peerVideo",
        `${getFirstNames(call.visitors)} ${
          remoteVideoTracks === 0
            ? t("call:peer.videoOff")
            : t("call:peer.videoOn")
        }`,
        "info"
      );
    }, [remoteVideoTracks, call.visitors, t]);

    // alert when remote audio pauses or resumes
    const remoteAudioTracks = peer?.stream.getAudioTracks().length;
    useEffect(() => {
      if (remoteAudioTracks === undefined) return;
      showToast(
        "peerAudio",
        `${getFirstNames(call.visitors)} ${
          remoteAudioTracks === 0
            ? t("call:peer.muted")
            : t("call:peer.unmuted")
        }`,
        "info"
      );
    }, [remoteAudioTracks, call.visitors, t]);

    let timeout: any;
    const onMouseMove = () => {
      setShowOverlay(true);
      (() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => setShowOverlay(false), 5000);
      })();
    };

    const handleSendMessage = useCallback(
      (contents: string) => {
        sendMessage(contents).catch((e) => {
          setErroredMessages((existing) => [
            ...existing,
            {
              user: {
                ...authInfo,
                role: "participant",
              },
              contents,
              timestamp: new Date(),
            },
          ]);
        });
      },
      [sendMessage, setErroredMessages, authInfo]
    );

    useChange(connectionState.quality, (previous, quality) => {
      switch (quality) {
        case "poor":
          if (!connectionState.videoDisabled) {
            const poorToast = () => {
              showToast(
                "connectionState",
                t("call:connectionQuality.poor"),
                "warning"
              );
              setDeferredPoorQualityToast(undefined);
            };
            if (localVideo?.paused) {
              setDeferredPoorQualityToast(() => poorToast);
            } else {
              poorToast();
            }
          }
          break;
        case "bad":
          setDeferredPoorQualityToast(undefined);
          break;
        case "excellent":
        case "good":
        case "average":
          if (["bad", "poor"].includes(previous || "unknown")) {
            showToast(
              "connectionStateImproved",
              t("call:connectionQuality.improved"),
              "success"
            );
          }
          setDeferredPoorQualityToast(undefined);
          break;
        default:
      }
    });

    useChange(connectionState.videoDisabled, (_previous, videoDisabled) => {
      if (videoDisabled) {
        setDroppedStreams(droppedStreams | DroppedStreams["local"]);
        showToast(
          "connectionState",
          !localVideo?.paused
            ? t("call:connectionQuality.bad")
            : t("call:connectionQuality.badWhenVideoPaused"),
          "error"
        );
      }
    });

    useChange(peer?.connectionState.quality, (previous, quality) => {
      if (quality === "poor" && previous !== "bad") {
        const peerFirstName = call.visitors[0]?.firstName;
        showToast(
          "poorConnectionState",
          t("call:connectionQuality.peerPoor", {
            peerFirstName,
          }),
          "warning"
        );
      }
    });

    useChange(
      peer?.connectionState.videoDisabled,
      (_previous, videoDisabled) => {
        if (videoDisabled) {
          setDroppedStreams(droppedStreams | DroppedStreams["peer"]);
          const peerFirstName = call.visitors[0]?.firstName;
          showToast(
            "peerVideo",
            t("call:connectionQuality.peerBad", {
              peerFirstName,
            }),
            "error"
          );
        }
      }
    );

    const allMessages = useMemo(
      () =>
        [
          ...messages.map((m) => ({ ...m, status: "sent" as const })),
          ...erroredMessages.map((m) => ({ ...m, status: "errored" as const })),
        ].sort((a, b) => a.timestamp.getDate() - b.timestamp.getDate()),
      [messages, erroredMessages]
    );

    if (status === "initializing") {
      return <Loader fullPage tip={`${t("common:loading")}...`} />;
    }

    const isCallEnding = !!(
      call.interval.endAt &&
      differenceInSeconds(new Date(call.interval.endAt), new Date()) <=
        FADING_ANIMATION_DURATION
    );
    return (
      <Layout>
        <div
          className="ant-layout-content flex flex-col bg-gray-800"
          onMouseMove={() => onMouseMove()}
          onMouseOver={() => onMouseMove()}
        >
          {peer &&
            (peer.stream.getVideoTracks().length > 0 ? (
              <div className="w-full h-full">
                <Video
                  srcObject={peer.stream}
                  className="m-auto h-screen"
                  autoPlay={true}
                  isFadingOut={isCallEnding}
                />
                <div className="absolute bottom-32 right-4 bg-black bg-opacity-50 py-1 px-2 rounded flex salign-center">
                  {!peer.stream.getAudioTracks().length && (
                    <AudioMutedOutlined className="text-red-600 text-base" />
                  )}
                  {!peer.stream.getVideoTracks().length && (
                    <VideoCameraOutlined className="text-red-600 text-base ml-1" />
                  )}
                  <Typography.Text className="text-white text-base ml-1">
                    {" "}
                    {getFirstNames(call.visitors)}
                  </Typography.Text>
                </div>
              </div>
            ) : (
              <AvatarGroup
                size={128}
                visitors={call.visitors}
                className="mx-auto my-auto"
              />
            ))}
          {localVideo && localVideo.stream && !localVideo.paused ? (
            <Video
              srcObject={localVideo.stream}
              className="w-64 absolute top-4 left-4 flex"
              autoPlay={true}
            />
          ) : (
            <VideoMePlaceholder
              initials={getInitials(getFullName(user))}
              height={`${(localVideo?.aspectRatio || 0.5) * 16}rem`}
              className="w-64"
            />
          )}
          {!peer && (
            <WaitingRoomCard
              call={call}
              title={
                peerEverConnected
                  ? t("call:waitingRoom.loading")
                  : `${t("call:waitingRoom.waitingForPrefix")} ${getFirstNames(
                      call.visitors
                    )} ${t("call:waitingRoom.waitingForSuffix")}...`
              }
              navigateBack={() =>
                peerEverConnected
                  ? onLeave("early_exit", getDropType(droppedStreams))
                  : push("/")
              }
              openInfoModal={openInfoModal}
              openTestConnectionModal={openTestConnectionModal}
            />
          )}
          <VideoOverlay
            loading={!(localAudio && localVideo)}
            audioOn={!!!localAudio?.paused}
            toggleAudio={() => {
              if (!localAudio) return;
              showToast(
                "microphone",
                `You ${
                  localAudio.paused ? "unmuted" : "muted"
                } your microphone`,
                "info"
              );
              toggleAudio();
            }}
            videoOn={!!!localVideo?.paused}
            videoButtonDisabled={!!connectionState.videoDisabled}
            toggleVideo={() => {
              if (!localVideo) return;
              showToast(
                "webcam",
                `You ${
                  localVideo.paused ? "turned on" : "turned off"
                } your webcam`,
                "info"
              );
              toggleVideo();
              deferredPoorQualityToast?.();
            }}
            chatCollapsed={chatCollapsed}
            toggleChat={() => {
              setChatCollapsed((collapsed) => !collapsed);
            }}
            leaveCall={() => {
              playLeaveCall();
              onLeave(
                peerEverConnected ? "early_exit" : "no_show",
                getDropType(droppedStreams)
              );
            }}
            hasUnreadMessages={hasUnreadMessages}
            startTime={call.interval.startAt}
            endTime={call.interval.endAt}
            showOverlay={showOverlay}
            onOverlayChange={() => {
              clearTimeout(timeout);
              timeout = setTimeout(() => setShowOverlay(false), 5000);
              setShowOverlay(true);
            }}
          />
        </div>
        {!chatCollapsed && (
          <Layout.Sider
            theme="light"
            className="h-screen mh-screen shadow"
            width={300}
            collapsible
            collapsed={chatCollapsed}
            trigger={null}
          >
            <div
              style={WRAPPER_PADDING}
              className="mt-3 h-screen mh-screen flex flex-col"
            >
              <Typography.Title level={3}>
                {t("call:chat.title")}
              </Typography.Title>
              <Typography.Text className="text-blue-500">
                {t("call:chat.monitorWarning")}
              </Typography.Text>
              <Chat messages={allMessages} onSend={handleSendMessage} visitorFullName={getFullName(call.visitors[0])} /> {/* TODO: drop assumption that there is only one visitor */}
            </div>
          </Layout.Sider>
        )}
      </Layout>
    );
  }
);
export default CallScreen;
