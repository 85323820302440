import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EnterCallSound from "src/assets/Sounds/EnterCall.wav";
import IntakeFormModal from "src/components/ChicagoSurvey/IntakeFormModal";
import { useIntakeForm } from "src/components/ChicagoSurvey/useIntakeForm";
import Dashboard from "src/components/Dashboard";
import PrivacyAcknowledgementModal from "src/components/Modals/PrivacyAcknowledgementModal";
import { GetMeetingsDocument } from "src/graphql/queries/GetMeetings.generated";
import { GetVisitorsDocument } from "src/graphql/queries/GetVisitors.generated";
import { useAppNavigate } from "src/hooks/useAppNavigate";
import "src/i18n/config";
import { useAppDispatch } from "src/redux";
import { openModal } from "src/redux/modules/modalsSlice";
import { Quote } from "src/types/Common";
import { Meeting } from "src/types/Meeting";
import { hasMeetingExpired, showToast } from "src/utils";
import useSound from "use-sound";

const DashboardPage: React.FC = () => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation("error");

  const [pageState, setPageState] = useState<
    "initial" | "intake_modal" | "privacy_modal"
  >();
  const [playEnterSound] = useSound(EnterCallSound);
  const [selected, setSelected] = useState<Meeting>();

  const { data: meetingsData } = useQuery(GetMeetingsDocument, {
    pollInterval: 30000,
    onError: (e) => {
      Sentry.captureException(e);
      showToast("fetchCalls", t("api.fetchCalls"), "error");
    },
  });

  const meetings = (
    meetingsData?.currentInmate?.meetings.edges.map((e) => e.node) || []
  )
    .filter((m) => !hasMeetingExpired(m))
    .sort(
      (a, b) =>
        new Date(a.interval.startAt).getTime() -
        new Date(b.interval.startAt).getTime()
    )
    .slice(0, 3);

  const { data } = useQuery(GetVisitorsDocument, {
    onError: (e) => {
      Sentry.captureException(e);
      showToast("fetchContacts", i18n.t("api.fetchContacts"), "error");
    },
  });
  const connections = data?.currentInmate?.connections || [];

  const { required } = useIntakeForm();

  const handleJoinCall = () => {
    if (!selected) return;
    playEnterSound();
    navigate(`/call/${selected.id}`);
    setPageState("initial");
  };

  const handleClose = () => {
    setSelected(undefined);
    setPageState("initial");
  };
  return (
    <div>
      <Dashboard
        meetings={meetings}
        connections={connections}
        joinCall={(meeting: Meeting) => {
          setSelected(meeting);
          setPageState("privacy_modal");
        }}
        seeAllCalls={() => navigate("/meetings")}
        onBiographyClick={(quote: Quote) =>
          dispatch(openModal({ activeType: "BIO_MODAL", entity: quote }))
        }
      />
      <PrivacyAcknowledgementModal
        visible={pageState === "privacy_modal"}
        onConfirm={() => {
          if (!selected) return;
          if (required) {
            setPageState("intake_modal");
          } else {
            handleJoinCall();
          }
        }}
        onClose={handleClose}
      />
      <IntakeFormModal
        visible={pageState === "intake_modal"}
        onConfirm={handleJoinCall}
        onClose={handleClose}
      />
    </div>
  );
};

export default DashboardPage;
