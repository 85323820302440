import { Avatar } from "antd";
import { AvatarSize } from "antd/lib/avatar/SizeContext";
import React from "react";
import { BasePersona } from "src/types/User";
import { getFullName, getInitials } from "src/utils";
import { getAvatarBackgroundColor } from "src/utils/UI";

interface Props {
  visitors: BasePersona[];
  size?: AvatarSize;
  className?: string;
}

const AvatarGroup: React.FC<Props> = ({ visitors, size, className }) => {
  return (
    <Avatar.Group className={className}>
      {visitors.map((visitor) => (
        <Avatar
          key={visitor.id}
          size={size}
          className={`${getAvatarBackgroundColor(
            getFullName(visitor)
          )} text-white`}
        >
          {getInitials(getFullName(visitor))}
        </Avatar>
      ))}
    </Avatar.Group>
  );
};

export default AvatarGroup;
