import { Button, Card, Row, Space, Typography } from "antd";
import { format } from "date-fns";
import { subMinutes } from "date-fns/esm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CallStatus, MeetingType } from "src/api/graphql";
import { WAITING_ROOM_BUFFER_MIN } from "src/constants";
import { Call, Meeting, meetingIsCall } from "src/types/Meeting";
import {
  getFullNames,
  getVisitLabel,
  intervalSecondsToMinutesLabel,
} from "src/utils";
import AvatarGroup from "../Avatar/AvatarGroup";
import MeetingIcon from "../Common/MeetingIcon";

interface Props {
  meeting: Meeting;
  selectMeeting: (meeting: Meeting) => void;
  joinCall: (call: Call) => void;
}

const MeetingTypeColor: Record<MeetingType, string> = {
  [MeetingType.VideoCall]: "#569FE1",
  [MeetingType.InPersonVisit]: "#F66262",
  [MeetingType.VoiceCall]: "#BBBBBB",
  [MeetingType.Webinar]: "#569FE1",
};

const MeetingItem: React.FC<Props> = ({
  meeting,
  selectMeeting,
  joinCall,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const [afterStart, setAfterStart] = useState(
    meeting.interval.startAt <= Date.now()
  );

  useEffect(() => {
    if (afterStart) return;
    const timer = setTimeout(
      () => setAfterStart(true),
      meeting.interval.startAt - Date.now()
    );
    return () => {
      clearTimeout(timer);
    };
  }, [meeting.interval.startAt, afterStart, setAfterStart]);

  const getMeetingButton = () => {
    const joinableNowOrSoon =
      meetingIsCall(meeting) &&
      subMinutes(meeting.interval.startAt, WAITING_ROOM_BUFFER_MIN) <
        new Date();

    if (joinableNowOrSoon) {
      return (
        <Button
          size="large"
          type="primary"
          className="rounded-sm"
          disabled={
            (meetingIsCall(meeting) &&
              meeting.call.status !== CallStatus.Active) ||
            !afterStart
          }
          onClick={() => {
            meetingIsCall(meeting) && joinCall(meeting);
          }}
        >
          {meetingIsCall(meeting) &&
          meeting.call.status === CallStatus.Active &&
          afterStart
            ? t("call.join")
            : t("call.initializing")}
        </Button>
      );
    }
    return (
      <Button
        size="large"
        className="rounded-sm"
        onClick={() => selectMeeting(meeting)}
      >
        {t("call.seeDetails")}
      </Button>
    );
  };

  return (
    <Card
      key={meeting.id}
      className="mb-3"
      style={{
        borderLeftColor: MeetingTypeColor[meeting.meetingType],
        borderLeftWidth: 3,
      }}
    >
      <Row justify="space-between" align="bottom">
        <Space direction="vertical">
          <Space align="center">
            <MeetingIcon meetingType={meeting.meetingType} className="mb-2" />
            <Typography.Title level={5}>
              {getVisitLabel(meeting.meetingType)}
            </Typography.Title>
          </Space>
          <Typography.Text>
            {format(new Date(meeting.interval.startAt), "h:mm aaa")} -{" "}
            {format(new Date(meeting.interval.endAt), "h:mm aaa")} •{" "}
            {`${intervalSecondsToMinutesLabel(meeting.interval.seconds)}`}
          </Typography.Text>
          <Space className="mt-4">
            <AvatarGroup visitors={meeting.visitors} />
            <Typography.Text type="secondary">
              {getFullNames(meeting.visitors)}
            </Typography.Text>
          </Space>
        </Space>
        <Space>{getMeetingButton()}</Space>
      </Row>
    </Card>
  );
};

export default MeetingItem;
