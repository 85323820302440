import { ReactComponent as VideoCamera } from "src/assets/videoCamera.svg";
import { ReactComponent as PeopleBurgundy } from "src/assets/peopleBurgundy.svg";
import { ReactComponent as PeopleRed } from "src/assets/peopleRed.svg";
import { PhoneOutlined } from "@ant-design/icons";

interface Props {
  meetingType: string;
  className?: string;
}

const MeetingIcon = ({ meetingType, className }: Props) => {
  const style = `${className}`;
  switch (meetingType) {
    case "call":
      return <VideoCamera className={style} />;
    case "in_person_contact":
      return <PeopleRed className={style} />;
    case "in_person_no_contact":
      return <PeopleBurgundy className={style} />;
    default:
      return <PhoneOutlined className={style} />;
  }
};

export default MeetingIcon;
