import { Col, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { FacilityFeature } from "src/api/graphql";
import useFacilityFeature from "src/hooks/useFacilityFeature";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

const FirstTimeUserModal = ({ onClose, isOpen }: Props) => {
  const { t } = useTranslation("messaging");
  const hasMessaging = useFacilityFeature(FacilityFeature.Emessaging);
  const steps = hasMessaging
    ? [
        {
          header: t("onboarding.steps.message.title"),
          detail: t("onboarding.steps.message.body"),
        },
        {
          header: t("onboarding.steps.call.title"),
          detail: t("onboarding.steps.call.body"),
        },
        {
          header: t("onboarding.steps.history.title"),
          detail: t("onboarding.steps.history.body"),
        },
      ]
    : [
        {
          header: t("onboarding.steps.history.title"),
          detail: t("onboarding.steps.history.body"),
        },
        {
          header: t("onboarding.steps.call.title"),
          detail: t("onboarding.steps.call.body"),
        },
      ];

  return (
    <Modal
      title={
        hasMessaging
          ? t("onboarding.messagingTitle")
          : t("onboarding.callsOnlyTitle")
      }
      visible={isOpen}
      onOk={onClose}
      onCancel={onClose}
      okText={t("onboarding.okBtn")}
      cancelButtonProps={{
        style: { display: "none" },
      }}
    >
      <div className="font-medium pb-2">{t("onboarding.steps.header")}</div>
      {steps.map(({ header, detail }, index) => (
        <div key={`firstTimeUserModal/${index}`}>
          <Row gutter={12}>
            <Col span={2} className="grid justify-items-end">
              <div className="h-6 w-6 flex justify-center items-center rounded-full border border-gray-400 text-gray-400">
                <p className="mb-px mr-px">{index + 1}</p>
              </div>
            </Col>
            <Col span={22}>
              <div className=" font-medium text-black-600/[0.45]">{header}</div>
            </Col>
          </Row>
          <Col offset={2} span={22}>
            <div className="pb-3">{detail}</div>
          </Col>
        </div>
      ))}
    </Modal>
  );
};

export default FirstTimeUserModal;
