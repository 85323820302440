import { Card, Col, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MeetingType } from "src/api/graphql";
import { Call, Meeting } from "src/types/Meeting";
import { groupBy } from "src/utils";
import { weekDays } from "src/utils/constants";
import MeetingItem from "./MeetingItem";

interface Props {
  meetings: Meeting[];
  allMeetingsLength: number;
  selectMeeting: (meeting: Meeting) => void;
  joinCall: (call: Call) => void;
  seeAllCalls: () => void;
}

const MeetingsList: React.FC<Props> = ({
  meetings,
  allMeetingsLength,
  selectMeeting,
  joinCall,
  seeAllCalls,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const [filteredMeetings, setFilteredMeetings] = useState<Meeting[]>([]);
  const [activeMeetingTab, setActiveMeetingTab] = useState<string>("allTypes");
  const tabList = [
    {
      key: "allTypes",
      tab: "All Types",
    },
    {
      key: "videoCalls",
      tab: "Video Calls",
    },
    {
      key: "inPersonVisits",
      tab: "In-Person Visits",
    },
  ];

  useEffect(() => {
    switch (activeMeetingTab) {
      case "allTypes":
        setFilteredMeetings(meetings);
        break;
      case "videoCalls":
        setFilteredMeetings(
          meetings.filter(
            (meeting) => meeting.meetingType === MeetingType.VideoCall
          )
        );
        break;
      case "inPersonVisits":
        setFilteredMeetings(
          meetings.filter(
            (meeting) => meeting.meetingType === MeetingType.InPersonVisit
          )
        );
        break;
    }
  }, [activeMeetingTab, meetings]);

  const getMeetingItems = () => {
    const meetingsByScheduledStart = groupBy(filteredMeetings, (meeting) =>
      new Date(meeting.interval.startAt).getDay()
    );
    const meetingItems = [];
    for (const startDay in meetingsByScheduledStart) {
      const meeting = meetingsByScheduledStart[startDay][0];
      const scheduledStart = meeting.interval.startAt;
      const startDate = new Date(scheduledStart);
      const date = `${new Date(scheduledStart)}, ${startDate.getDay()}`;
      const values = date.split(",");
      const scheduledStartDate = values[0];
      const weekDay = weekDays[parseInt(values[1])];

      const dayOfMonth = new Date(scheduledStartDate).getDate();
      meetingItems.push(
        <Row key={weekDay} justify="start">
          <Col flex={0.1}>
            <Space direction="vertical" align="center">
              <Typography.Title level={5} type="secondary">
                {weekDay.toUpperCase()}
              </Typography.Title>
              <Typography.Text strong>{dayOfMonth}</Typography.Text>
            </Space>
          </Col>
          <Col flex="auto">
            {meetingsByScheduledStart[startDay].slice(0, 5).map((meeting) => (
              <MeetingItem
                meeting={meeting}
                selectMeeting={selectMeeting}
                joinCall={joinCall}
                key={meeting.id}
              />
            ))}
          </Col>
        </Row>
      );
    }
    return meetingItems;
  };

  return (
    <Card
      title={"Events"}
      tabList={tabList}
      activeTabKey={activeMeetingTab}
      onTabChange={(key) => setActiveMeetingTab(key)}
      extra={
        <Typography.Link onClick={seeAllCalls}>
          {`${t("call.seeAll")} (${allMeetingsLength})`}
        </Typography.Link>
      }
      className="rounded-md"
    >
      {getMeetingItems()}
    </Card>
  );
};

export default MeetingsList;
