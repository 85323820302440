import Icon, { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";
import { UserType, UsState } from "src/api/graphql";
import DialPadSvg from "src/assets/Icons/DialpadIcon";
import { ReactComponent as Logo } from "src/assets/logo.svg";
import NumberPad from "src/components/NumberPad";
import { BACKGROUNDS } from "src/constants";
import { GetFacilitiesDocument } from "src/graphql/queries/GetFacilities.generated";
import { LoginUserDocument } from "src/graphql/queries/LoginUser.generated";
import "src/i18n/config";
import { RootState, useAppSelector } from "src/redux";
import { BORDER_RADIUS } from "src/styles/Layout";
import { Facility } from "src/types/Facility";
import { reportApolloError, showToast } from "src/utils";
import { LANGUAGES } from "src/utils/constants";
import { USState } from "src/utils/enums/USState.enum";
import { getRandomItem } from "src/utils/utils";
import "./index.css";

const { Content } = Layout;

const FORM_LAYOUT = {
  labelCol: { flex: "95px" },
  wrapperCol: { flex: 1 },
};

const FORM_TAIL_LAYOUT = {
  wrapperCol: { span: 24 },
};

type TParams = { state?: UsState };

interface PropsInterface extends RouteComponentProps<TParams> {
  token: string;
  setToken: Function;
}

const AMEELIO_WEB_AVAILABLE: string[] = ["ASP"];

const AMEELIO_WEB_ENFORCED = [
  "CCF",
  "CRC",
  "FDCF",
  "ICIW",
  "IMCC",
  "ISP",
  "MPCF-MLO",
  "MPCF-MSU",
  "NCCF",
  "NCF",
  "TEST",
];

function redirectToAmeelioWeb(systemCode: string): void {
  window.location.href = `https://${systemCode.toLowerCase()}-app.ameelio.org`;
}

const LoginContainer: React.FC<PropsInterface> = (props) => {
  const { t, i18n } = useTranslation("login");
  const { Option } = Select;

  const session = useAppSelector((state: RootState) => state.session);

  const [form] = Form.useForm();
  const facilityId = Form.useWatch("facilityId", form);
  const [background] = useState(getRandomItem(BACKGROUNDS));
  const [state, setState] = useState<UsState | undefined>(
    props.match.params.state
  );
  const [padVisible, setPadVisible] = useState(false);
  const [redirecting, setRedirecting] = useState<boolean>();

  const { data, error } = useQuery(GetFacilitiesDocument, {
    onError: (e) => {
      Sentry.captureException(e);
      showToast(
        "fetchFacilities",
        "Could not load list of facilities",
        "error"
      );
    },
  });

  const [loginUser, { error: loginError, loading: loginLoading }] =
    useLazyQuery(LoginUserDocument, {
      onError: (e) => {
        if (e.message === "invalid credentials") {
          const message =
            "Invalid username, ID, or password. Please try again.";
          Sentry.captureException(e);
          showToast("login", message, "error");
        } else {
          reportApolloError(e, "Login/index/loginUser");
        }
      },
    });

  let history = useHistory();

  if (error || loginError) throw error;

  if (props.token) {
    history.push("/");
  }

  const onFinish = async (values: any) => {
    const username = `${values.inmateNumber}-${values.facilityId}`;
    const response = await loginUser({
      variables: {
        input: {
          username,
          password: values.pin,
          userType: UserType.Inmate,
        },
      },
    });

    const token = response.data?.loginUser.authToken || "";
    props.setToken(token);
  };

  const facilities = data?.facilities || [];
  const facilityStates = facilities.map((facility) => facility.state);

  // Hide Alaska in prod, we'll use it for prod tests
  const uniqueStates = Array.from(new Set(facilityStates)).filter((state) =>
    process.env.NODE_ENV === "production" ? state !== UsState.Ak : true
  );
  const stateOptions = uniqueStates.map((state) => (
    <Option key={`option-${state}`} value={state}>
      {USState[state as keyof typeof USState]}
    </Option>
  ));

  const facilityOptions = facilities.map((facility: Facility) => {
    if (facility.state === state) {
      return (
        <Option key={`option-${facility.id}`} value={facility.id}>
          {facility.name}
        </Option>
      );
    }
    return null;
  });

  const selectedFacility = facilities.find((f) => f.id === facilityId);
  useEffect(() => {
    const enforceRedirect = AMEELIO_WEB_ENFORCED.includes(
      selectedFacility?.publicId || ""
    );
    if (!enforceRedirect) return setRedirecting(false);
    setRedirecting(true);
    const timer = setTimeout(
      () => redirectToAmeelioWeb(selectedFacility!.system.code),
      3000
    );
    return () => {
      clearTimeout(timer);
    };
  }, [selectedFacility]);

  const offerAmeelioWeb = AMEELIO_WEB_AVAILABLE.includes(
    selectedFacility?.publicId || ""
  );

  return (
    <Content
      className="banner-background flex items-center"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Modal
        visible={redirecting}
        className="rounded-sm"
        onOk={() => {
          redirectToAmeelioWeb(selectedFacility!.system.code);
        }}
        onCancel={() => {
          form.setFieldValue("facilityId", undefined);
        }}
      >
        Redirecting you to the new Ameelio experience.
      </Modal>
      <div
        className="bg-white m-auto"
        style={{
          ...BORDER_RADIUS,
          width: "450px",
          minHeight: "400px",
          padding: "24px",
        }}
      >
        <Space
          direction="vertical"
          className="p-0 m-0 flex justify-between"
          size="large"
        >
          <Row>
            <Logo className="login-logo" />
          </Row>
          <Row justify="center">
            <Col>
              <Typography.Text>
                {t("caption")}{" "}
                <Typography.Link href="/tos.pdf" target="_blank">
                  {t("tos")}{" "}
                </Typography.Link>
              </Typography.Text>
            </Col>
          </Row>

          <Form
            {...FORM_LAYOUT}
            form={form}
            name="login"
            className="w-full rounded-lg"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            onFinish={onFinish}
            initialValues={{
              language: Object.keys(LANGUAGES)[0],
              pin:
                process.env.NODE_ENV === "development"
                  ? process.env.REACT_APP_DEV_INMATE_PIN
                  : "",
              inmateNumber:
                process.env.NODE_ENV === "development"
                  ? process.env.REACT_APP_DEV_INMATE_ID
                  : "",
            }}
          >
            <Form.Item
              label={t("placeholder.language")}
              labelAlign={"left"}
              name="language"
            >
              <Radio.Group
                className="w-full"
                onChange={(e) => i18n.changeLanguage(e.target.value)}
              >
                {Object.entries(LANGUAGES).map(([key, value]) => (
                  <Radio.Button key={key} value={key}>
                    {value}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="state"
              label={t("placeholder.state")}
              labelAlign={"left"}
            >
              <Select
                onSelect={(value: UsState) => setState(value)}
                value={state}
                defaultValue={state}
              >
                {stateOptions}
              </Select>
            </Form.Item>

            <Form.Item
              name="facilityId"
              label={t("placeholder.facility")}
              labelAlign={"left"}
              rules={[{ required: true, message: "Must select a facility." }]}
            >
              <Select disabled={!state}>{facilityOptions}</Select>
            </Form.Item>

            {selectedFacility && offerAmeelioWeb && (
              <Alert
                type="info"
                message={
                  <>
                    <Typography.Text
                      style={{ display: "block" }}
                      className="mb-2"
                    >
                      We've been working on a new and improved Ameelio
                      experience! Try it now before it's released to everyone.
                    </Typography.Text>
                    <Typography.Link
                      onClick={() =>
                        redirectToAmeelioWeb(selectedFacility.system.code)
                      }
                    >
                      Try the new Ameelio
                    </Typography.Link>
                  </>
                }
                style={{ marginBottom: "24px" }}
              />
            )}

            <Form.Item
              name="inmateNumber"
              labelAlign={"left"}
              label={t("placeholder.inmateNumber")}
              rules={[{ required: true, message: "ID Number is required." }]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t("placeholder.inmateNumber")}
                disabled={!state}
              />
            </Form.Item>

            <Form.Item
              label="PIN Code"
              labelAlign={"left"}
              name="pin"
              rules={[{ required: true, message: "Password is required." }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder={t("placeholder.pinCode")}
                addonAfter={
                  <Icon
                    component={DialPadSvg}
                    onClick={() => setPadVisible((collapsed) => !collapsed)}
                    className="text-blue-600 text-lg"
                  />
                }
                disabled={!state}
              />
            </Form.Item>

            <Form.Item
              {...FORM_TAIL_LAYOUT}
              style={{ borderRadius: 4, margin: 0 }}
            >
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={session.status === "loading" || loginLoading}
                disabled={state === null}
              >
                {t("buttonText")}
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </div>
      <NumberPad
        setInput={(input: string) => {
          form.setFieldsValue({ pin: input });
          setPadVisible(false);
        }}
        visible={padVisible}
      />{" "}
    </Content>
  );
};

export default LoginContainer;
