import Modal from "antd/lib/modal/Modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import GoodbyeLottie from "src/assets/Lottie/Goodbye.json";
import { useIntakeForm } from "src/components/ChicagoSurvey/useIntakeForm";
import { getLottieOptions } from "src/utils/UI";
import SurveyButton from "../Common/Buttons/SurveyButton";

interface Props {
  visible?: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export default function IntakeFormModal({
  onConfirm,
  onClose,
  visible,
}: Props): ReactElement {
  const { t } = useTranslation(["dashboard"]);
  const { onCompleteForm, formId } = useIntakeForm();

  if (!formId) return <div />;

  return (
    <Modal
      visible={visible}
      className="rounded-sm w-1/2"
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      title={t("dashboard:intakeForm.required.title")}
      onCancel={onClose}
    >
      <div className="flex flex-col justify-center items-center gap-4">
        <Lottie {...getLottieOptions(GoodbyeLottie)} className="w-64" />
        <span>{t("dashboard:intakeForm.required.body")}</span>
        <SurveyButton
          type="primary"
          onSubmit={async () => {
            onConfirm();
            await onCompleteForm();
          }}
          formId={formId}
          className="self-end"
        />
      </div>
    </Modal>
  );
}
