import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { MessageFragmentFragmentDoc } from '../fragments/MessageFragment.generated';
export type CreateMessageMutationVariables = Types.Exact<{
  input: Types.CreateMessageInput;
}>;


export type CreateMessageMutation = { __typename?: 'Mutation', createMessage: { __typename?: 'CreateMessageResponse', event: { __typename?: 'ConnectionTimelineEvent', id: string }, message: { __typename?: 'Message', id: string, availability: Types.MessageAvailability, createdAt: number, content: string, isRead: boolean, statusDetails: string | null, sender: { __typename?: 'Inmate', id: string, firstName: string } | { __typename?: 'Visitor', id: string, firstName: string }, connection: { __typename?: 'Connection', id: string } } } };


export const CreateMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"event"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"message"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MessageFragment"}}]}}]}}]}},...MessageFragmentFragmentDoc.definitions]} as unknown as DocumentNode<CreateMessageMutation, CreateMessageMutationVariables>;