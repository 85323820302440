import { useEffect, useRef } from "react";
import useChangeValue from "./useChangeValue";

export default function useChange<T>(
  value: T,
  callback: (previous: T | null, current: T) => void
): void {
  const delta = useChangeValue(value);
  const cbRef = useRef(callback);
  cbRef.current = callback;
  useEffect(() => {
    cbRef.current(delta.previous, delta.current);
  }, [delta]);
}
