import { Typography } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Countdown, { CountdownTimeDelta } from "react-countdown";
import FiveMinutesLeftSound from "src/assets/Sounds/VoiceCallFiveMinutesLeft.mp3";
import OneMinutesLeftSound from "src/assets/Sounds/VoiceCallOneMinuteLeft.mp3";
import useSound from "use-sound";

interface Props {
  endTime: number;
  timerValue: number;
  onUpdateTimer?: () => void; // propagate when the timer changes countup/down state
  playAudio?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

// Renderer callback with condition
const renderer = ({
  formatted,
  completed,
}: {
  formatted: { minutes: string; seconds: string };
  minutes: number;
  seconds: number;
  completed: boolean;
}) => {
  return completed ? (
    <div />
  ) : (
    <Typography.Text
      className={` px-6 py-4 rounded-md text-lg bg-black opacity-65 text-white`}
    >
      {formatted.minutes}:{formatted.seconds} left
    </Typography.Text>
  );
};
export const Timer = ({
  playAudio,
  endTime, //ms epoch
  timerValue, //seconds
  onUpdateTimer,
  style,
  className,
}: Props) => {
  // TODO: https://app.shortcut.com/ameelio/story/3180/create-reminder-sound-for-the-call-alert

  const [isCountingDown, setIsCountingDown] = useState(false);
  const [playFiveMinutesAlert] = useSound(FiveMinutesLeftSound);
  const [playOneMinuteAlert] = useSound(OneMinutesLeftSound);

  const handleTick = (timeDelta: CountdownTimeDelta) => {
    if ([60 * 1000, 5 * 60 * 1000].includes(timeDelta.total)) {
      setIsCountingDown(true);
      if (onUpdateTimer) onUpdateTimer();
      if (playAudio) {
        if (timeDelta.total === 5 * 60 * 1000) {
          playFiveMinutesAlert();
        }
        if (timeDelta.total === 60 * 1000) {
          playOneMinuteAlert();
        }
      }
    }
    if (timeDelta.total === (4 * 60 + 56) * 1000) {
      //at 4 minutes 56 seconds
      setIsCountingDown(false);
      if (onUpdateTimer) onUpdateTimer();
    }
  };

  return (
    <div className={`w-36 flex flex-col items-center ${className}`}>
      <div style={style} hidden={!isCountingDown}>
        <Countdown
          date={endTime}
          renderer={renderer}
          zeroPadTime={2}
          onTick={handleTick}
        />
      </div>
      <div hidden={isCountingDown} className={className}>
        <CountUp seconds={timerValue} />
      </div>
    </div>
  );
};

export const useTimer = () => {
  const [value, setValue] = useState(0);
  const timeoutId = useRef<NodeJS.Timer>();
  const start = useCallback((startValue: number) => {
    const startTime = Date.now();
    setValue(startValue);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setInterval(() => {
      const value = Math.floor((Date.now() - startTime) / 1000) + startValue;
      setValue(value);
    }, 1000);
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, []);

  return { start, value };
};

interface CountUpProps {
  seconds: number;
}

export const CountUp = (props: CountUpProps) => {
  const { seconds } = props;
  return (
    <div className="px-6 rounded-md text-lg">
      {secondsToMinutesAndSeconds(seconds)}
    </div>
  );
};

function secondsToMinutesAndSeconds(seconds: number | undefined): string {
  if (!seconds) return "";
  const minutes = Math.floor(seconds / 60);
  const remainder = `${seconds - minutes * 60}`.padStart(2, "0");
  return `${minutes}:${remainder}`;
}
