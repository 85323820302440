import { addMinutes } from "date-fns";
import { Interval } from "src/api/graphql";

type MediaType = "video" | "audio";

function getMediaConstraints(
  type: MediaType,
  deviceId?: number
): MediaStreamConstraints {
  if (type === "audio") {
    return { audio: true };
  } else {
    return {
      video: true,
    };
  }
}

export async function getMedia(type: MediaType, deviceId?: number) {
  const mediaConstraints = getMediaConstraints(type, deviceId);
  return await navigator.mediaDevices.getUserMedia(mediaConstraints);
}

export function getFirstNames(visitors: { firstName: string }[]) {
  return visitors.map((v) => v.firstName).join(", ");
}

export function getFullNames(
  visitors: { firstName: string; lastName: string }[]
) {
  return visitors.map((v) => `${v.firstName} ${v.lastName}`).join(", ");
}

export function hasMeetingExpired(meeting: { interval: Interval }) {
  return meeting.interval.endAt
    ? addMinutes(new Date(meeting.interval.endAt), 15) < new Date()
    : false;
}

export const isNegativeCallRating = (rating?: number) =>
  !!(rating && rating <= 2);

export const hasIncompleteCallRating = (rating: number, feedback?: string) =>
  !rating || (rating <= 2 && !feedback);
