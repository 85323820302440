import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { GetMeDocument } from "src/graphql/queries/GetMe.generated";
import { useAuthInfo } from "src/hooks/useAuthInfo";
import Loader from "../Loader";

export interface ProtectedRouteProps extends RouteProps<string, any> {
  authenticationPath: string;
  token: string;
  setUser: Function;
  logout: Function;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { token, setUser, logout } = props;
  let redirectPath = "";
  const { setAuthInfo } = useAuthInfo();

  if (!token) {
    redirectPath = props.authenticationPath;
  }

  const { loading: userLoading } = useQuery<any>(GetMeDocument, {
    onError: (e) => {
      Sentry.captureException(e);
      logout();
    },
    onCompleted: (d) => {
      setAuthInfo({
        id: d?.me?.id,
        token,
        type: "inmate",
      });
      setUser(d.me);
    },
  });

  if (userLoading) {
    return <Loader fullPage={true} />;
  } else if (redirectPath) {
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...props} />;
  }
};

export default ProtectedRoute;
