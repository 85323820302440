import { useTranslation } from "react-i18next";
import { ReactComponent as DesertIllustration } from "src/assets/desertIllustration.svg";

interface Props {}
const NoConnectionsView = (props: Props) => {
  const { t } = useTranslation("messaging");
  return (
    <div className="mx-auto my-auto flex flex-col justify-center items-center text-black text-opacity-25">
      <DesertIllustration />
      <div className="font-medium pb-2">
        {t("connections.noConnections.title")}
      </div>
      <div className="text-center w-80">
        {t("connections.noConnections.body")}
      </div>
    </div>
  );
};

export default NoConnectionsView;
