import { Card, Col, Row, Space, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "src/components/Common/PageLayout";
import useUser from "src/hooks/useUser";
import { AVATAR_LARGE } from "src/utils/constants";
import { getFullName } from "src/utils/utils";

interface Props {
  onEdit: () => void;
}

const ProfileInfo: React.FC<Props> = ({ onEdit }) => {
  const user = useUser();
  const { t } = useTranslation("profile");
  const fullName = getFullName(user);

  return (
    <PageLayout>
      <Space direction="vertical" size="large" className="w-full p-6 pt-9">
        <Card className="flex justify-between">
          <Space>
            <Avatar src={user.profileImageUrl} size={AVATAR_LARGE} />
            <Space direction="vertical">
              <Typography.Title level={4}>{fullName}</Typography.Title>
              <Typography.Link onClick={onEdit}>
                {t("profileInfo.editProfile")}
              </Typography.Link>
            </Space>
          </Space>
        </Card>
        <Row gutter={32}>
          <Col span={14}>
            <Card title={t("profileInfo.about")}>
              <Space direction="vertical" className="opacity-65">
                <Typography.Text>{`${t(
                  "profileInfo.name"
                )}: ${fullName}`}</Typography.Text>
                <Typography.Text>{`${t("profileInfo.birthday")}: ${format(
                  new Date(user.dateOfBirth),
                  "MMMM d, yyyy"
                )}`}</Typography.Text>
              </Space>
            </Card>
          </Col>
        </Row>
      </Space>
    </PageLayout>
  );
};

export default ProfileInfo;
