import { Modal } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  recipientFirstName: string;
  statusDetails: string;
}

const MessageDetailModal = ({
  onClose,
  isOpen,
  recipientFirstName,
  statusDetails,
}: Props) => {
  const { t } = useTranslation("messaging");
  return (
    <Modal
      title={t("messages.messageRejection.title")}
      visible={isOpen}
      onOk={onClose}
      onCancel={onClose}
      okText="OK"
      width={570}
      cancelButtonProps={{
        style: { display: "none" },
      }}
    >
      <p className="">
        {t("messages.messageRejection.body", {
          statusDetails,
          recipientFirstName,
        })}
      </p>
    </Modal>
  );
};

export default MessageDetailModal;
