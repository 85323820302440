import {
  CallStatus,
  Interval,
  MeetingStatus,
  MeetingType,
} from "src/api/graphql";
import { VisitorFragmentFragment } from "src/graphql/fragments/VisitorFragment.generated";

export function meetingIsCall(meeting: Meeting): meeting is Call {
  return meeting.meetingType === MeetingType.VideoCall;
}

export type Meeting = {
  id: string;
  status: MeetingStatus;
  meetingType: MeetingType;
  interval: Interval;
  visitors: Omit<VisitorFragmentFragment, "__typename">[];
  call: {
    status: CallStatus;
  } | null;
};

export type Call = Omit<Meeting, "meetingType" | "call"> & {
  meetingType: MeetingType.VideoCall;
  call: {
    status: CallStatus;
    url: string | null;
    token: string | null;
  };
};

export type VoiceCall = Omit<Meeting, "meetingType" | "call"> & {
  meetingType: MeetingType.VoiceCall;
  call: {
    status: CallStatus;
    url: string | null;
    token: string | null;
  };
};

export type InPersonVisit = Omit<Meeting, "meetingType" | "call"> & {
  meetingType: MeetingType.InPersonVisit;
  call: null;
};
