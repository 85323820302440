import { useQuery } from "@apollo/client";
import { GetMeDocument } from "src/graphql/queries/GetMe.generated";
import { Inmate } from "src/types/User";

const useUser = () => {
  const { data: { me } = {}, error } = useQuery<any>(GetMeDocument, {
    fetchPolicy: "cache-only",
  });

  if (!me || error) {
    throw new Error("Unable to fetch user data");
  }

  const user: Inmate = me;

  return user;
};

export default useUser;
