import { ThunderboltFilled } from "@ant-design/icons";
import { Badge, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { ParticipantComment } from "src/api/graphql";

interface Props {
  className?: string;
  firstName: string;
  createdAt: number;
  comment: ParticipantComment;
}

const ParticipantCommentCard: React.FC<Props> = ({
  className,
  firstName,
  comment,
}: Props) => {
  const { t } = useTranslation("messaging");

  const getCommentString = (comment: ParticipantComment) => {
    switch (comment) {
      case ParticipantComment.CallBackFiveMinutes:
        return t("messages.participantComment.CALL_BACK_FIVE_MINUTES");
      case ParticipantComment.CallBackNow:
        return t("messages.participantComment.CALL_BACK_NOW");
      case ParticipantComment.CallBackTwoMinutes:
        return t("messages.participantComment.CALL_BACK_TWO_MINUTES");
      case ParticipantComment.CantTalkNow:
        return t("messages.participantComment.CANT_TALK_NOW");
      default:
        return ""; // If the comment is a meeting scheduling comment (a distinct feature), don't show it.
    }
  };
  const commentString = getCommentString(comment);
  return commentString === "" ? (
    <div />
  ) : (
    <div className={className}>
      <Badge.Ribbon
        text={
          <Tooltip title={t("messages.participantCommentPopover")}>
            <div className="pb-2">
              <ThunderboltFilled />
            </div>
          </Tooltip>
        }
        color="gold"
      >
        <div className="border border-gray grid gap-2 p-2 bg-white rounded-t-lg rounded-r-lg">
          <Typography.Text type="secondary" className="text-xs pr-8">
            {firstName}
          </Typography.Text>
          {commentString}
        </div>
      </Badge.Ribbon>
    </div>
  );
};

export default ParticipantCommentCard;
