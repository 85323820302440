import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type EndVoiceCallMutationVariables = Types.Exact<{
  input: Types.EndVoiceCallInput;
}>;


export type EndVoiceCallMutation = { __typename?: 'Mutation', endVoiceCall: { __typename?: 'EndVoiceCallResponse', meeting: { __typename?: 'Meeting', id: string, status: Types.MeetingStatus, call: { __typename?: 'Call', duration: number | null } | null, facility: { __typename?: 'Facility', publicId: string, maxVoiceCallDuration: number | null }, inmates: Array<{ __typename?: 'Inmate', id: string, remainingVoiceCallDuration: number, remainingVoiceCallDurationResetTime: number | null, remainingVoiceCallTimeQuota: number }> } } };


export const EndVoiceCallDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EndVoiceCall"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EndVoiceCallInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"endVoiceCall"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meeting"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"call"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"duration"}}]}},{"kind":"Field","name":{"kind":"Name","value":"facility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"publicId"}},{"kind":"Field","name":{"kind":"Name","value":"maxVoiceCallDuration"}}]}},{"kind":"Field","name":{"kind":"Name","value":"inmates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"remainingVoiceCallDuration"}},{"kind":"Field","name":{"kind":"Name","value":"remainingVoiceCallDurationResetTime"}},{"kind":"Field","name":{"kind":"Name","value":"remainingVoiceCallTimeQuota"}}]}}]}}]}}]}}]} as unknown as DocumentNode<EndVoiceCallMutation, EndVoiceCallMutationVariables>;