import { useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useState } from "react";
import { UpdateInmateDocument } from "src/graphql/mutations/UpdateInmate.generated";
import { reportApolloError } from "src/utils";
import { GetIntakeFormStatusDocument } from "./GetIntakeFormStatus.generated";

/*
 *  determines whether we should prompt the incarcerated person to
 *  fill out the form for the impact study w/ we're conducting with UChicago
 */
type IntakeFormStatus = "optional" | "required" | "completed" | "unavailable";

export const useIntakeForm = (): {
  onCompleteForm: () => Promise<void>;
  // specifies whether we should require user to submit
  required: boolean;
  // returns form ID if form needs to filled out
  formId?: string;
} => {
  const [intakeFormStatus, setIntakeFormStatus] =
    useState<IntakeFormStatus>("unavailable");
  const { data: currentInmateData } = useQuery(GetIntakeFormStatusDocument, {
    onError: (e) => {
      reportApolloError(e, "useIntakeForm/GetIntakeFormStatusDocument");
    },
  });

  const inmate = currentInmateData?.currentInmate;

  const isSurveyAvailable = useCallback(async () => {
    const FORM_ID = process.env.REACT_APP_INTAKE_FORM;
    if (!FORM_ID) return false;

    const TIMEOUT_MS = 10000;
    try {
      await Promise.race([
        fetch(`https://form.typeform.com/to/${FORM_ID}`),
        new Promise<Response>((_, reject) =>
          setTimeout(() => reject(new Error("timeout")), TIMEOUT_MS)
        ),
      ]);
      return true;
    } catch (err) {
      Sentry.captureException(err);
      return false;
    }
  }, []);

  const getIntakeFormStatus = useCallback(async () => {
    if (inmate?.hasFilledIntakeForm) return "completed";
    if (!(await isSurveyAvailable())) return "unavailable";
    const meetings = inmate?.endedCalls.edges.map((e) => e.node) || [];
    const isSeasonedUser = meetings.length > 5;
    return isSeasonedUser ? "required" : "optional";
  }, [inmate, isSurveyAvailable]);

  useEffect(() => {
    (async () => {
      setIntakeFormStatus(await getIntakeFormStatus());
    })();
  }, [getIntakeFormStatus]);

  const [updateProfile] = useMutation(UpdateInmateDocument, {
    onError: (e) => {
      reportApolloError(e, "useIntakeForm/updateProfile");
    },
  });
  const onCompleteForm = useCallback(async () => {
    if (!inmate?.id) return;
    await updateProfile({
      variables: {
        input: { inmateId: inmate.id, hasFilledIntakeForm: true },
      },
    });
  }, [updateProfile, inmate?.id]);

  return {
    onCompleteForm,
    formId:
      intakeFormStatus === "required" || intakeFormStatus === "optional"
        ? process.env.REACT_APP_INTAKE_FORM!
        : undefined,
    required: intakeFormStatus === "required",
  };
};
