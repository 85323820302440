import { useQuery } from "@apollo/client";
import { Button } from "antd";
import { push } from "connected-react-router";
import React, { Suspense, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { CallStatus } from "src/api/graphql";
import CallScreen, { StreamDropType } from "src/components/Call/Call";
import { CallExitType } from "src/components/CallFeedback/CallFeedback";
import ErrorPage from "src/components/Error";
import Loader from "src/components/Loader";
import { GetCallDocument } from "src/graphql/queries/GetCall.generated";
import "src/i18n/config";
import { useAppDispatch } from "src/redux";
import { openModal } from "src/redux/modules/modalsSlice";
import { enterFullScreen, exitFullScreen } from "src/redux/modules/UI";
import { FAQResource } from "src/types/UI";
import { hasMeetingExpired } from "src/utils";
const CiscoCallScreen = React.lazy(() => import("src/components/Call/CiscoCall"));

type TParams = { id: string };

/**
 * CallPage handles the call route and is responsible for finding and validated
 * the requested call record, like any other page. It does not understand how
 * call rooms work.
 */
const CallPage: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
  const dispatch = useAppDispatch();

  const callId = match.params.id;
  const { data, loading, error } = useQuery(GetCallDocument, {
    variables: { meetingId: callId },
    fetchPolicy: "no-cache", // do not cache access token
  });

  const { t } = useTranslation(["error"]);

  // escape the layout that we shouldn't be in
  useEffect(() => {
    dispatch(enterFullScreen());
    return () => {
      dispatch(exitFullScreen());
    };
  }, [dispatch]);

  const leaveCall = useCallback(
    (exitType: CallExitType, droppedStreams: StreamDropType) =>
      dispatch(push(`/feedback/${callId}/${exitType}/${droppedStreams}`)),
    [callId, dispatch]
  );

  if (error) throw new Error(error.message);
  if (loading || !data) return <Loader fullPage />;

  if (
    !data.meeting.call ||
    data.meeting.call.status !== CallStatus.Active ||
    hasMeetingExpired(data.meeting)
  )
    return (
      <ErrorPage
        status="error"
        title={t("error:call.callNull")}
        extra={[
          <Button
            type="primary"
            size="large"
            onClick={() => dispatch(push("/"))}
            key={0}
          >
            {t("error:call.returnHome")}
          </Button>,
        ]}
      />
    );

  return data.meeting.isCisco ?
    (<Suspense fallback={<Loader fullPage/>}>
      <CiscoCallScreen
        call={data.meeting}
        push={(path: string) => dispatch(push(path))}
        openInfoModal={(resource: FAQResource) =>
          dispatch(openModal({ activeType: "RESOURCE_MODAL", entity: resource }))
        }
        openTestConnectionModal={() =>
          dispatch(
            openModal({ activeType: "TEST_CONNECTION_MODAL", entity: null })
          )
        }
        onLeave={leaveCall}
      />
    </Suspense>) :
    (<CallScreen
      call={data.meeting}
      push={(path: string) => dispatch(push(path))}
      openInfoModal={(resource: FAQResource) =>
        dispatch(openModal({ activeType: "RESOURCE_MODAL", entity: resource }))
      }
      openTestConnectionModal={() =>
        dispatch(
          openModal({ activeType: "TEST_CONNECTION_MODAL", entity: null })
        )
      }
      onLeave={leaveCall}
    />);
};

export default CallPage;
