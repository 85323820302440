import { Layout } from "antd";
import { useEffect, useRef } from "react";
import { ConnectionTimelineEventVerb, MeetingType } from "src/api/graphql";
import { GetConnectionTimelineQuery } from "src/graphql/queries/GetConnectionTimeline.generated";
import { getMessageSenderType } from "src/utils/Message";
import ConnectionCreationCard from "./ConnectionCreationCard";
import ConnectionRestrictionCard from "./ConnectionRestrictionCard";
import MeetingCard from "./MeetingCard";
import MessageCard from "./MessageCard";
import ParticipantCommentCard from "./ParticipantCommentCard";
import VoiceCallCard from "./VoiceCallCard";

type TimelineEvent =
  GetConnectionTimelineQuery["connection"]["timelineEvents"]["edges"][0]["node"];

interface Props {
  events: TimelineEvent[];
  recipientFirstName: string;
  className?: string;
}

const EventTimelineList = ({ events, recipientFirstName }: Props) => {
  const focusRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!focusRef.current) return;
    focusRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [focusRef, events]);

  return (
    <Layout.Content className="bg-gray-50 flex flex-col overflow-y-auto gap-2 py-2">
      {events.map((event) => {
        const { object } = event;
        switch (object.__typename) {
          case "Message":
            const senderType = getMessageSenderType(object);
            const messageOffset = senderType === "visitor" ? "" : "ml-auto";
            return (
              <div
                key={event.object.id}
                className={`px-4 py-1 ${messageOffset}`}
              >
                <MessageCard
                  message={object}
                  className={`max-w-l ${messageOffset}`}
                  recipientFirstName={recipientFirstName}
                />
              </div>
            );
          case "Meeting":
            return object.meetingType === MeetingType.VoiceCall ? (
              <>
                <VoiceCallCard
                  key={event.object.id}
                  meeting={object}
                  className={`max-w-sm ml-auto mr-4`}
                />
                {object.participantComment ? (
                  <ParticipantCommentCard
                    className={`max-w-sm mr-auto ml-4`}
                    firstName={recipientFirstName}
                    createdAt={object.interval.startAt}
                    comment={object.participantComment}
                  />
                ) : null}
              </>
            ) : (
              <MeetingCard
                key={event.object.id}
                meeting={object}
                className={`max-w-sm mr-auto ml-4`}
              />
            );
          case "Connection":
            if (event.verb === ConnectionTimelineEventVerb.Create)
              return (
                <ConnectionCreationCard
                  connectionFirstName={recipientFirstName}
                  createdAt={event.createdAt}
                />
              );
            else return <div />; //"modify" event; TODO design
          case "ConnectionRestriction":
            return (
              <ConnectionRestrictionCard
                connectionFirstName={recipientFirstName}
                service={object.service}
                createdAt={event.createdAt}
                expiresAt={object.expiresAt}
              />
            );
          default:
            return <div />;
        }
      })}
      <div ref={focusRef} />
    </Layout.Content>
  );
};

export default EventTimelineList;
